import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Radio, Button, Modal, Form, Input, Slider, InputNumber, Statistic, Select, Divider } from 'antd';
import CountUp from 'react-countup';
import { CaretRightOutlined } from '@ant-design/icons';
import { activesCategoryList } from '../../../actions/actives';
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { Segmented, Typography, ConfigProvider } from 'antd';
import moment from 'moment';
import Countdown from 'react-countdown';
import { schemaColorActives } from '../../../components/schemaColorActives';


function declineWord(number, forms) {
    number = Math.abs(number) % 100;
    const num = number % 10;

    if (number > 10 && number < 20) {
        return forms[2];
    }
    if (num > 1 && num < 5) {
        return forms[1];
    }
    if (num === 1) {
        return forms[0];
    }
    return forms[2];
}

const dayForms = ['день', 'дня', 'дней'];

const Completionist = () => <span>You are good to go!</span>;

const renderer = ({ hours, minutes, seconds, completed, days }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <span>{days} {declineWord(days, dayForms)} {hours}:{minutes}:{seconds}</span>;
    }
};
function ActiveBlock({ showModal, data, showModalInfo }) {

    

    return (
        <div className='activeBlock'>
            <div className="activeBlock_Header">
                <div className='activeBlock_Header_Name'>{data.name}</div>
                {data.active_transaction.filter(el => el.status === 'finish').length ?

                    <div className='activeBlock_Header_Action'>
                        <Row gutter={[5, 5]}>
                            <Col>
                                <div className='activeBlock_Header_Action_Block confirm'>Завершено: {data.active_transaction.filter(el => el.status === 'finish').length}</div>
                            </Col>
                            {/* <Col>
                            <div className='activeBlock_Header_Action_Block cancel'>Отменено: 23</div>
                        </Col>
                        <Col>
                            <div className='activeBlock_Header_Action_Block waiting'>Ожидание: 2</div>
                        </Col> */}
                        </Row>
                    </div>

                    :
                    <></>
                }

                <div className='activeBlock_Header_Tegs'>
                    <Row gutter={[5, 5]}>
                        {data.categories.map((data, index) => (
                            <Col>
                                <div className={`activeBlock_Header_Teg ${schemaColorActives[index]}`}>{data}</div>
                            </Col>
                        ))}

                        {/* <Col>
                            <div className='activeBlock_Header_Teg Credit'>Credit</div>
                        </Col>
                        <Col>
                            <div className='activeBlock_Header_Teg Multi-Asset'>Multi-Asset</div>
                        </Col> */}
                    </Row>

                </div>
            </div>
            <div className="activeBlock_Body">
                <>

                    {data.active_transaction.filter(el => el.status === 'active').length ?
                        <div className='activeBlock_Body_Item_Active'>
                            <div className='activeBlock_Body_Item_Stat'>
                                <div className='activeBlock_Body_Item_Stat--topBlock'>
                                    <div>
                                        <h5>Вложенная сумма</h5>
                                    </div>
                                    <div>
                                        <h5>Процент</h5>
                                    </div>
                                </div>
                                <div className='activeBlock_Body_Item_Stat--bottomBlock'>
                                    <div>
                                        <span style={{ fontWeight: 'bold', }}>${data.active_transaction.find(el => el.status === 'active').amount_income}</span>
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: 'bold', }}>{data.active_transaction.find(el => el.status === 'active').percent}%</span>
                                    </div>
                                </div>
                            </div>
                            <div className='activeBlock_Body_Item_Profit'>
                                <h5>Накопленные дивиденды:</h5>
                                <span style={{fontSize: 18}}>${data.active_transaction.find(el => el.status === 'active').amount_profit}</span>
                            </div>
                            <div className='activeBlock_Body_Item_Countdown'>
                                <h5>Заканчивается через:</h5>
                                <div>
                                    {/* <Countdown value={deadline} onFinish={onFinish} format="DD:HH:mm" /> */}

                                    {/* <Countdown
                                        value={countdownValue > 0 ? Date.now() + countdownValue : Date.now()}
                                        format="HH:mm:ss"
                                        onFinish={() => alert("Countdown finished!")}
                                    /> */}
                                    <Countdown renderer={renderer} date={moment(data.active_transaction.find(el => el.status === 'active').date_end)} />

                                </div>
                            </div>
                        </div> :
                        <div className="activeBlock_Body_Item">
                            <div className="activeBlock_Body_Item_Left">
                                <div className="activeBlock_Body_Item_Left_Name">Сумма</div>
                                {data.profit.map((data, index) => (
                                    <div className="activeBlock_Body_Item_Left_Price">
                                        <div>до <span style={{ fontWeight: 'bold', }}>${data.from_amount}</span></div>
                                        <div>до <span style={{ fontWeight: 'bold', }}>${data.to_amount}</span></div>
                                    </div>
                                ))}
                            </div>

                            <div className="activeBlock_Body_Item_Right">
                                <div className="activeBlock_Body_Item_Right_Name">Процент</div>

                                {data.profit.map((data, index) => (
                                    <div className="activeBlock_Body_Item_Left_Price">
                                        <div>до <span style={{ fontWeight: 'bold', }}>{data.from_percent}%</span></div>
                                        <div>до <span style={{ fontWeight: 'bold', }}>{data.to_percent}%</span></div>
                                    </div>
                                ))}
                            </div>

                            <div className="activeBlock_Body_Item_Center">
                                <span>Срок: <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{data.period} {declineWord(data.period, dayForms)}</span></span>
                            </div>
                        </div>
                    }



                </>
                <div className="activeBlock_Body_Footer">
                    {data.active_transaction.filter(el => el.status === 'waiting').length ?
                        <>
                            <div>
                                <div>Ожидает подтверждения</div>
                            </div>
                        </> : data.active_transaction.filter(el => el.status === 'active').length ? <>
                            <div style={{ height: '0', padding: '0' }}>

                            </div>
                        </>
                            : <>
                                <div onClick={() => showModal(data)}>
                                    <div>Инвестировать</div>
                                    <CaretRightOutlined />
                                </div>
                            </>}

                    <div onClick={() => showModalInfo(data)}>
                        <div>Подробности вложений</div>
                        <CaretRightOutlined />
                    </div>
                </div>
            </div>

        </div>
    )
}

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ activesCategoryList }, dispatch)
);

export default widthConnect(ActiveBlock);
