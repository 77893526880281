import {    
    HISTORY_REQ_FAIL,
    HISTORY_REQ_SUCCESS,
    HISTORY_REQ_START
} from '../actions/history';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case HISTORY_REQ_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case HISTORY_REQ_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case HISTORY_REQ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default historyReducer;





