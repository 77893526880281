import { activesApi, activesCategoryListApi, openActiveApi } from "../api/active";
// import { verifyCustomer } from './customerActions';
// все вызовы переделай на await


// Действия для проверки кода из SMS
export const ACTIVES_REQ_START = 'ACTIVES_REQ_START';
export const ACTIVES_REQ_SUCCESS = 'ACTIVES_REQ_SUCCESS';
export const ACTIVES_REQ_FAIL = 'ACTIVES_REQ_FAIL';

export const ACTIVES_CATEGORY_LIST_REQ_START = 'ACTIVES_CATEGORY_LIST_REQ_START';
export const ACTIVES_CATEGORY_LIST_REQ_SUCCESS = 'ACTIVES_CATEGORY_LIST_REQ_SUCCESS';
export const ACTIVES_CATEGORY_LIST_REQ_FAIL = 'ACTIVES_CATEGORY_LIST_REQ_FAIL';

export const ACTIVES_OPEN_REQ_START = 'ACTIVES_OPEN_REQ_START';
export const ACTIVES_OPEN_REQ_SUCCESS = 'ACTIVES_OPEN_REQ_SUCCESS';
export const ACTIVES_OPEN_REQ_FAIL = 'ACTIVES_OPEN_REQ_FAIL';

const activesStart = () => {
    return { type: ACTIVES_REQ_START };
};

const activesSuccess = (data) => {
    return { type: ACTIVES_REQ_SUCCESS, payload: data };
};

const activesFail = (error) => {
    return { type: ACTIVES_REQ_FAIL, payload: error };
};

const categoriesStart = () => {
    return { type: ACTIVES_CATEGORY_LIST_REQ_START };
};

const categoriesSuccess = (data) => {
    return { type: ACTIVES_CATEGORY_LIST_REQ_SUCCESS, payload: data };
};

const categoriesFail = (error) => {
    return { type: ACTIVES_CATEGORY_LIST_REQ_FAIL, payload: error };
};

const activeOpenStart = () => {
    return { type: ACTIVES_OPEN_REQ_START };
};

const activeOpenSuccess = (data) => {
    return { type: ACTIVES_OPEN_REQ_SUCCESS, payload: data };
};

const activeOpenFail = (error) => {
    return { type: ACTIVES_OPEN_REQ_FAIL, payload: error };
};





export const activesList = () => {
    return (dispatch) => {
        dispatch(activesStart());
        activesApi()
            .then((response) => {
                dispatch(activesSuccess(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(activesFail(error.response.data.error));
            });

    };
};




export const activesCategoryList = () => {
    return (dispatch) => {
        dispatch(categoriesStart());

        activesCategoryListApi()
            .then((response) => {
                dispatch(categoriesSuccess(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(categoriesFail(error.response.data.error));
            });
    };
};

// export const walletsTypesAll = () => {
//     return (dispatch) => {
//         walletsTypesAllApi()
//             .then((response) => {
//                 dispatch(walletsTypesAllReq(response.data));
//             })
//             .catch((error) => {
//                 console.log(error);
//                 dispatch(walletsTypesAllReqFail(error.response.data.error));
//             });
//     };
// };


export const openActive = (data) => {
    return (dispatch) => {
        dispatch(activeOpenStart());

        openActiveApi(data)
            .then((response) => {
                dispatch(activeOpenSuccess(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(activeOpenFail(error.response.data.error));
            });
    }
}



