import { historyApi } from "../api/history";


export const HISTORY_REQ_START = 'HISTORY_REQ_START';
export const HISTORY_REQ_SUCCESS = 'HISTORY_REQ_SUCCESS';
export const HISTORY_REQ_FAIL = 'HISTORY_REQ_FAIL';

const moment = require('moment');


const historyStart = () => {
    return { type: HISTORY_REQ_START };
};

const historySuccess = (data) => {
    return { type: HISTORY_REQ_SUCCESS, payload: data };
};

const historyFail = (error) => {
    return { type: HISTORY_REQ_FAIL, payload: error };
};



export const historyList = () => {
    return (dispatch) => {
        dispatch(historyStart());
        historyApi()
            .then((response) => {
                dispatch(historySuccess(response.data.map(el => ({
                    ...el,
                    date: el.date ? moment(el.date).format('DD.MM.YYYY HH:mm') : ''
                }))));
            })
            .catch((error) => {
                dispatch(historyFail(error.response.data.error));
            });

    };
};



