import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Tag, Tooltip, Divider, ConfigProvider, Input, Slider, InputNumber, Statistic } from 'antd';
import CountUp from 'react-countup';
import { CaretRightOutlined } from '@ant-design/icons';
import { FaRegQuestionCircle } from "react-icons/fa";
import { schemaColorActives } from '../../../components/schemaColorActives';

const moment = require('moment');

const text = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.';



function declineWord(number, forms) {
    number = Math.abs(number) % 100;
    const num = number % 10;

    if (number > 10 && number < 20) {
        return forms[2];
    }
    if (num > 1 && num < 5) {
        return forms[1];
    }
    if (num === 1) {
        return forms[0];
    }
    return forms[2];
}

const statusMapping = {
    finish: 'Завершено',
    waiting: 'В ожидании',
    active: 'Активно',
};

const statusMappingColor = {
    finish: 'green',
    waiting: 'orange',
    active: 'blue',
};

const dayForms = ['день', 'дня', 'дней'];

const ActiveBlock = ({ showModal, detail, data }) => {

    return (
        <div className='activeBlockDetail'>
            <div className="activeBlock_Header">
                <div className='activeBlock_Header_Name'>{data.name}</div>
                <div className='activeBlock_Header_Tegs'>
                    <Row gutter={[5, 5]}>
                        {data.categories.map((data, index) => (
                            <Col>
                                <div className={`activeBlock_Header_Teg ${schemaColorActives[index]}`}>{data}</div>
                            </Col>
                        ))}
                    </Row>

                </div>
            </div>
            <div className="activeBlock_Body">
                <div className="activeBlock_Body_Item">
                    <div className="activeBlock_Body_Item_Title">Таблица активов</div>
                    <div className="activeBlock_Body_Item_Table">
                        <div className="activeBlock_Body_Item_Table_Item">
                            <div>
                                <div>Сумма</div>
                            </div>
                            <div>
                                <div>Процент</div>
                            </div>
                        </div>

                        {data.profit.map((data, index) => (
                            <div className="activeBlock_Body_Item_Table_Item">
                                <div>
                                    <div>от ${data.from_amount}</div>
                                    <div>до ${data.to_amount}</div>
                                </div>
                                <div>
                                    <div>от {data.from_percent}%</div>
                                    <div>до {data.to_percent}%</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="activeBlock_Body_Item_Center">
                        <span>Срок {data.period} {declineWord(data.period, dayForms)}</span>
                    </div>
                    <div className="activeBlock_Body_Footer">
                        <div>
                            <div onClick={showModal}>Инвестировать</div>
                            <CaretRightOutlined />
                        </div>
                    </div>
                </div>
                <div className="activeBlock_Body_History">
                    <div className="activeBlock_Body_History_Title">Вложения</div>
                    <div className="activeBlock_Body_History_Items">
                        {data.active_transaction.length ? <></> :
                            <div className="activeBlock_Body_History_Item">
                                <div className='emptyBlock'>Нет актуальных вложений</div>
                            </div>
                        }
                        {
                            data.active_transaction.map((data, index) => (
                                <div className="activeBlock_Body_History_Item">
                                    <div>
                                        <div>
                                            {data.date_start ?   moment(data.date_start).format('DD.MM.YYYY HH:mm')  : '-'}
                                        </div>
                                        <Divider type="vertical" />
                                        <div>
                                            ${data.amount_income}
                                        </div>
                                        <Divider type="vertical" />
                                        <div>
                                            <Tag color={statusMappingColor[data.status]}>
                                                {statusMapping[data.status]}
                                            </Tag>
                                        </div>
                                    </div>
                                    <CustomDivider />
                                </div>
                            ))
                        }

                        <div className="activeBlock_Body_History_Item">
                            <div>Подробности вложений</div>
                            <Tooltip placement="left" title={data.description}>
                                <FaRegQuestionCircle />
                            </Tooltip>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}


class CustomDivider extends React.Component {
    render() {
        return (
            <ConfigProvider
                theme={{
                    components: {
                        Divider: {
                            marginLG: '10px',
                        },
                    },
                }}
            >
                <Divider />
            </ConfigProvider>
        )
    }
}

export default ActiveBlock;