import { auth, regRequest, customersInfoRequest } from '../api/customersApi';


export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';

export const REG_START = 'REG_START';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';


export const GET_CUSTOMERS_INFO_START = 'GET_CUSTOMERS_INFO_START';
export const GET_CUSTOMERS_INFO_SUCCESS = 'GET_CUSTOMERS_INFO_SUCCESS';
export const GET_CUSTOMERS_INFO_FAIL = 'GET_CUSTOMERS_INFO_FAIL';


const loginStart = () => {
    return { type: LOGIN_START };
};

const loginSuccess = (data) => {
    return { type: LOGIN_SUCCESS, payload: data };
};

const loginFail = (error) => {
    return { type: LOGIN_FAIL, payload: error };
};

const getCustomersInfoStart = () => {
    return { type: GET_CUSTOMERS_INFO_START };
};

const getCustomersInfoSuccess = (data) => {
    return { type: GET_CUSTOMERS_INFO_SUCCESS, payload: data };
};

const getCustomersInfoFail = (error) => {
    return { type: GET_CUSTOMERS_INFO_FAIL, payload: error };
};

const regStart = () => {
    return { type: REG_START };
};

const regSuccess = (data) => {
    return { type: REG_SUCCESS, payload: data };
};

const regFail = (error) => {
    return { type: REG_FAIL, payload: error };
};


export const logout = () => {
    return { type: LOGOUT };
};





// Асинхронная функция для проверки кода из SMS
export const login = (mail, pass) => {
    return (dispatch) => {
        dispatch(loginStart());
        auth(mail, pass)
            .then((response) => {
                dispatch(loginSuccess(response.data));
               
            })
            .catch((error) => {
                console.log(error);
                dispatch(loginFail(error.response.data.message));
            });
    };
};

export const getCustomersInfo = () => {
    return (dispatch) => {
        dispatch(getCustomersInfoStart());
        customersInfoRequest()
            .then((response) => {
                dispatch(getCustomersInfoSuccess(response.data));
               
            })
            .catch((error) => {
                console.log(error);
                dispatch(getCustomersInfoFail(error.response.data.message));
            });
    };
};

export const registration = (data) => {
    return (dispatch) => {

        dispatch(regStart());

        regRequest(data)
            .then((response) => {
                dispatch(regSuccess(response.data));
            })
            .catch((error) => {
                console.log(error);
                dispatch(regFail(error.response.data.message));
            });

    };
};

export const logoutUser = () => {
    return (dispatch) => {
        localStorage.clear();
        dispatch(logout());
    };
};






