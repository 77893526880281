import React, { useEffect, useState } from 'react';
import { Row, Col, Radio, Button, Modal, Form, Input, Slider, InputNumber, Statistic, Select, Divider } from 'antd';



export default function ModalInfo({ isModalOpen, setIsModalOpen, data }) {


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <>

            <Modal title={data.name} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Row>
                    <Col span={24}>
                        <p>
                            {data.description}
                        </p>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
