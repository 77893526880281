import React, { useEffect, useState } from 'react';
import { Row, Col, Radio, Button, Modal, Form, Input, Slider, InputNumber, Statistic, Select, Divider } from 'antd';
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openActive, activesList } from '../../../actions/actives';
import { getCustomersInfo } from '../../../actions/customer';

function ModalCustom({ isModalOpen, setIsModalOpen, data, openActive, actives, activesList, getCustomersInfo}) {
    const [inputValue, setInputValue] = useState(500);
    const onChange = (newValue) => {
        setInputValue(newValue);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = () => {
        openActive({id: data.id, amount: inputValue})
    };

    useEffect(() => {
        if(actives.success){
            getCustomersInfo()
            activesList( )
            setIsModalOpen(false);
        }
    }, [actives.success])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            <Modal className='CustomModal' width={900} title={''} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}

                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    size="large"
                >
                    <label style={{ fontSize: '18px' }}>{data.name}</label>

                    <Form.Item
                        name="Slider"
                    >
                        <div className='formCategoryList'>{data.categories?.join(', ')}</div>
                        {/* <Input style={{ borderRadius: 10, height: 50 }} defaultValue={data.categories} /> */}
                    </Form.Item>
                    <label style={{ fontSize: '18px' }}>Сумма вложений</label>
                    <Form.Item
                        name="Slider"
                        labelCol={{ style: { fontSize: '18px' } }} // Изменение размера шрифта label

                    >
                        <Row>
                            <Col span={20}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 7px' }}>
                                    <div>
                                        $500
                                    </div>
                                    <div>
                                        $99 999
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <AiFillCaretRight style={{
                                        fontSize: '24px',
                                        marginRight: '-10px'
                                    }} />

                                    <Slider
                                        min={500}
                                        max={999999}
                                        // value={inputValue}
                                        value={typeof inputValue === 'number' ? inputValue : 0}
                                        onChange={onChange}
                                        style={{ width: '100%', margin: '0', color: 'black' }}
                                        value={typeof inputValue === 'number' ? inputValue : 0}
                                        handleStyle={{ borderColor: 'black', backgroundColor: 'black' }}
                                        trackStyle={{ backgroundColor: 'black' }}
                                        railStyle={{ backgroundColor: 'black' }}
                                        trackStyle={{ backgroundColor: 'black' }}


                                        handleStyle={{ backgroundColor: 'black', borderColor: 'black' }} // Изменение цвета кружка на черный
                                        trackStyle={{ backgroundColor: 'black' }} // Изменение цвета активной части дорожки на черный
                                        railStyle={{ backgroundColor: 'black' }} // Изменение цвета неактивной части дорожки на черный
                                        dotStyle={{ borderColor: 'black' }} // Изменение цвета всех меток на черный
                                        activeDotStyle={{ borderColor: 'black' }} // Изменение цвета активной метки на черный
                                    />
                                    <AiFillCaretLeft style={{
                                        fontSize: '24px',
                                        marginLeft: '-10px'
                                    }} />
                                </div>
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    prefix={'$'}
                                    size="large" 
                                    min={500}
                                    max={999999}
                                    style={{
                                        width: '100%',
                                        margin: '0',
                                        height: 50
                                    }}
                               
                                    value={inputValue}
                                    onChange={onChange}

                                />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 24,
                        }}
                    >
                        <Button disabled={actives.loadingSend} style={{
                            width: '100%',
                            backgroundColor: '#9D968C'
                        }} type="primary" htmlType="submit" >
                            Отправить запрос
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ openActive, activesList, getCustomersInfo   }, dispatch)
);

export default widthConnect(ModalCustom);
