import { lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RootRoute from './routes'
import { activesList } from './actions/actives'
import Disclaimer from './components/Disclaimer'
 
function IdurarOs({ customer, activesList }) {

  useEffect(() => {
    console.log(customer.isAuthenticated);
    if (customer.isAuthenticated) {
      activesList()
    }
  }, [customer.isAuthenticated]);

  return (
    <>
      <RootRoute isAuthenticated={customer?.isAuthenticated} />
      <Disclaimer/>
    </>
  )

}

const widthConnect = connect(
  (state) => ({ ...state }),
  (dispatch) => bindActionCreators({ activesList }, dispatch)
);

export default widthConnect(IdurarOs);