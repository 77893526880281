import { React, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HomePage from '../pages/HomePage';
import Dashboard from '../pages/Dashboard';
import Portfolio from '../pages/Portfolio'
import Table from '../pages/Table'

import RegisterPage from '../pages/RegisterPage'
import LoginPage from '../pages/LoginPage'

import NotFound from '../pages/NotFound'
import ProtectedLoginRoute from './ProtectedLoginRoute';
import ProtectedRoute from './ProtectedRoute';
import CreateProcessingActive from '../pages/CreateProcessingActive';
import ForgotPassword from '../pages/ForgotPassword';



export default function RootRoute({ isAuthenticated }) {

  const [isAuth, setIsAuth] = useState(true);
  return (
    <Routes>
      <Route
        path="/"
        element={<ProtectedLoginRoute element={<LoginPage setIsAuth={setIsAuth} />} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/login"
        element={<ProtectedLoginRoute element={<LoginPage setIsAuth={setIsAuth} />} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/register"
        element={<ProtectedLoginRoute element={<RegisterPage setIsAuth={setIsAuth} />} isAuthenticated={isAuthenticated} />}
      />
      <Route
        path="/login/forgot-password"
        element={<ProtectedLoginRoute element={<ForgotPassword setIsAuth={setIsAuth} />} isAuthenticated={isAuthenticated} />}
      />
      
      {/* Защищенный маршрут */}

      <Route
        path="/home"
        element={<ProtectedRoute element={<Dashboard />} isAuthenticated={isAuthenticated} />}
      />

      <Route
        path="/active/create/:id"
        element={<ProtectedRoute element={<CreateProcessingActive />} isAuthenticated={isAuthenticated} />}
      />

      <Route
        path="/details"
        element={<ProtectedRoute element={<Portfolio />} isAuthenticated={isAuthenticated} />}
      />

      <Route
        path="/history"
        element={<ProtectedRoute element={<Table />} isAuthenticated={isAuthenticated} />}
      />

      {/* Маршрут для 404 */}
      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  );
}



