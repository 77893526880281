import React, { useEffect, useState } from 'react';
import { Alert, Flex, Spin } from 'antd';


const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
};
const content = <div style={contentStyle} />;


export default function Preloader({ }) {
    const [spinning, setSpinning] = React.useState(false);
    const [percent, setPercent] = React.useState(0);
    const showLoader = () => {
        setSpinning(true);
        let ptg = -10;
        const interval = setInterval(() => {
            ptg += 5;
            setPercent(ptg);
            if (ptg > 120) {
                clearInterval(interval);
                setSpinning(false);
                setPercent(0);
            }
        }, 100);
    };

    useEffect(() => {
        showLoader()
    }, [])

    return (
        <div class="preloader ">
            <Spin tip="Loading" size="large" fullscreen>
                {content}
            </Spin>
        </div>
    )
}



