import { queries } from '@testing-library/react';
import { sendRequest } from './request';



// Функция для проверки кода из SMS и получения JWT токена
export const activesApi = () => {
    return sendRequest('/actives', 'GET');
};

export const activesToCategoryApi = (category) => {
    return sendRequest(`/actives`, 'GET', {} ,category);
};

export const activesCategoryListApi = () => {
    return sendRequest(`/actives/categories`, 'GET');
};

export const openActiveApi = (data) => {
    return sendRequest(`/actives/waiting`, 'POST', data);
};

// export const walletsTypesAllApi = (id) => {
//     return sendRequest(`/wallets/types/all`, 'GET');
// };




