import React from 'react';
import { GrPrevious } from "react-icons/gr";
import LayoutCustom from '../../components/Layout';
import { Tabs, Select, ConfigProvider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FaRegBell } from "react-icons/fa";

function HeaderLayout({ title, isSelect, prevPath }) {
    const handleGoBack = () => {
        navigate(-1);
    };
    const navigate = useNavigate();


    return (
        <>
            <div className='Page__select__block'>
                {/* <div className='Page__select_block_nav nav_Btn' onClick={prevPath ? handleGoBack : null}>
                    {prevPath ? (<GrPrevious />) : null} 

                </div>*/}
                <div className='Page__select__block_alert'>
                    <FaRegBell />
                </div>
                <h2>{title}</h2>
                {!isSelect ? (
                    <ConfigProvider
                        theme={{
                            components: {
                                Select: {
                                    clearBg: 'rgb(0, 0, 0, 0%)',
                                    selectorBg: 'rgb(0, 0, 0, 0%)',
                                    colorTextPlaceholder: 'rgb(0, 0, 0)',
                                    colorTextQuaternary: 'rgba(0, 0, 0)',
                                    multipleItemColorDisabled: 'rgba(255, 255, 255)',
                                    optionSelectedColor: 'rgba(255, 255, 255)',
                                    colorText: 'rgba(0, 0, 0)',
                                    colorBgElevated: 'rgba(58, 58, 58, 0.9)',
                                    optionSelectedBg: 'rgba(38, 38, 38, 0.6)',
                                    colorBorder: 'transparent',
                                    colorPrimary: 'transparent',
                                    colorPrimaryHover: 'transparent',

                                },
                            },
                        }}
                    >
                        <Select
                            className='homePage__select'
                            defaultValue="Москва"
                            style={{
                                width: 'auto',
                                backgroundColor: 'transparent',
                            }}
                            options={[
                                {
                                    value: 'Москва',
                                    label: 'Москва',
                                },
                                {
                                    value: 'Питер',
                                    label: 'Питер',
                                },
                                {
                                    value: 'Воронеж',
                                    label: 'Воронеж',
                                },
                            ]} />
                    </ConfigProvider>

                ) : null}
            </div>

        </>
    );
}

export default HeaderLayout;