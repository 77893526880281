import customerReducer from './customer'
import activesReducer from './actives'
import historyReducer from './history'


export default {
    customer: customerReducer,
    actives: activesReducer,
    history: historyReducer,
};
