import React, { useState } from 'react';



const Disclaimer = ({ }) => {
    const [subscribeDoNo, setSubscribeDoNo] = useState(null)
    const [subscribe, setSubscribe] = useState(() => {
        return localStorage.getItem("disclaimerAccepted") === "true";
    });

    const handleAccept = () => {
        setSubscribe(true);
        localStorage.setItem("disclaimerAccepted", "true");
    };

    if (subscribe) {
        return null;
      }

    return (
        <>
            <div id="disclaimer" class="reveal-modal open" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="" tabindex="0" aria-hidden="false" style={{ display: 'block', opacity: 1, visibility: 'visible', top: '100px' }}>
                {subscribeDoNo == null ? <>
                    <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                    <h2>DISCLAIMER</h2>
                    <h5>Please read this page before proceeding, as it explains certain restrictions imposed by law on the distribution of this information and the countries in which our funds are authorised for sale. It is your responsibility to be aware of and to observe all applicable laws and regulations of any relevant jurisdiction.</h5>

                    <div class="terms-container">
                        <p><strong>TERMS OF USE</strong></p>
                        <p>FOR EEA USERS: This site and the information on it is neither directed at nor made available to retail clients. It is directed only at persons who are professional investors (for the purposes of the Alternative Investment Fund Managers Directive (2011/61/EU) (known as ‘AIFMD’); professional clients or eligible counterparties for the purposes of the Markets in Financial Instruments Directive (Directive 2004/39/EC) (known as ‘MiFID’); or as otherwise defined under applicable local regulations and at whom this site and the information on it may lawfully be directed in any relevant jurisdiction. The definition of professional investor under AIFMD can be found <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32011L0061&amp;from=EN">here</a> and the definitions of professional client and eligible counterparty under MiFID can be found <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:02004L0039-20110104&amp;from=EN">here</a>. Please contact us if you require any further information on your status.</p>
                        <p>FOR HONG KONG USERS: This site and the information on it is directed only at, and made available only to, professional investors as defined in the Securities and Futures Ordinance (Cap. 571, the laws of Hong Kong, “the Ordinance”) and its subsidiary legislation, Securities and Futures (Professional Investor) Rules (“PI Rules”).&nbsp; Any investment/subscription from a Hong Kong investor will only be accepted if such investor is a professional investor. Please refer to the links for the definitions of professional investors under the <a href="http://www.legislation.gov.hk/blis_pdf.nsf/6799165D2FEE3FA94825755E0033E532/5167961DDC96C3B7482575EF001C7C2D/$FILE/CAP_571_e_b5.pdf">Ordinance</a> and <a href="http://www.legislation.gov.hk/blis_pdf.nsf/6799165D2FEE3FA94825755E0033E532/7E7E9E6B95063DE1482575EF001D1A93/$FILE/CAP_571D_e_b5.pdf">PI Rules</a>. Please contact us if you require any further information on your status.</p>
                        <p>By accepting these Terms of Use, you hereby certify that you are a Professional Investor, Professional Client or an Eligible Counterparty.</p>
                        <p class="fivehundred">1. About Brevan Howard</p>
                        <p>The Brevan Howard website ‘portal.brevanhoward.com’ (‘our site’) is issued for all purposes by Brevan Howard Capital Management LP (‘<strong>BHCM LP</strong>’), acting by its sole general partner, Brevan Howard Capital Management Limited (‘<strong>BHCML</strong>’) (and the expression ‘<strong>we</strong>’ and ‘<strong>our</strong>’ shall be construed accordingly). We are registered with the Jersey Financial Services Commission under the Financial Services (Jersey) Law, 1998 to carry on fund services business as a manager, investment manager and distributor. We, in such capacity, have appointed certain of our affiliates, including Brevan Howard Asset Management LLP (‘<strong>BHAM</strong>’), to act as investment manager of, and provide certain related services to, certain funds. BHAM is authorised and regulated by the Financial Conduct Authority of the United Kingdom (‘FCA’) (firm reference number: 216851). BHCML (acting as sole general partner of BHCM LP) and each of its affiliates are collectively referred to in this site as ‘Brevan Howard’ and references to ‘Brevan Howard’ are to BHCML (acting as sole general partner of BHCM LP) and/or its relevant affiliate.</p>
                        <p>2. Your use of our site</p>
                        <p>These Terms of Use set out the terms on which you may make use of our site and apply to every page on our site, whether or not such pages are individually disclaimed. Please read these Terms of Use carefully before you start to use our site. By using our site, you have indicated that you accept these Terms of Use and that you agree to abide by them. If you do not agree to these Terms of Use, please refrain from using our site. These Terms of Use also incorporate our <a href="https://p1web01v/cookies-and-privacy/">Cookies and Privacy Policy</a>, and all other rules, policies and procedures that may be published from time to time on our site, each of which is incorporated by reference and each of which may be updated by us from time to time without notice to you.</p>
                        <p>In the United Kingdom, the issue and communication of this website has been approved by BHAM. If you are in the United Kingdom or elsewhere in the EEA, you should note that our site may contain information or documents which constitute a financial promotion for the purposes of the Financial Services and Markets Act 2000 and the FCA Rules. Accordingly, our site is issued only to and/or is directed only at persons who are professional clients or eligible counterparties as defined in the FCA Rules. We neither provide investment advice to, nor receive and transmit orders from, investors in any funds nor do we carry on any other activities with or for such investors that constitute ‘MiFID or equivalent third country business’ for the purposes of the FCA Rules.</p>
                        <p>We may revise these Terms of Use from time to time by updating this page. The revised Terms of Use will take effect when they are posted. You are expected to check this page from time to time and to take notice of any changes we may have made, as by using our site you acknowledge that you have accepted any such amendments.</p>
                        <p>You must <strong>NOT</strong> use our site (or permit or procure others to use it) as follows:</p>
                        <p>(A) &nbsp; for any unlawful, improper or illegal purpose or activity;<br />
                            (B) &nbsp;&nbsp;to violate our or any third party’s copyright, trademark, proprietary or other intellectual property rights;<br />
                            (C) &nbsp;&nbsp;to damage our name or reputation or that of our affiliates or any third parties;<br />
                            (D) &nbsp;to impersonate any of our employees or other person or use a false name while using our site or implying an association with us;<br />
                            (E) &nbsp;&nbsp;to penetrate our security measures or other entities’ systems (“hacking”);<br />
                            (F) &nbsp;&nbsp;to generate excessive amounts of internet traffic, to interfere with our network or other’s use of our site or to engage in activities designed to or having the effect of degrading or denying service to users of our site or others;<br />
                            (G) &nbsp;&nbsp;to introduce viruses, worms, harmful code and/or Trojan horses onto the internet or into our site or any other entity’s systems and it is your responsibility to ensure that whatever you download or select for your use from our site is free from such items; and/or<br />
                            (H) &nbsp;&nbsp;to transmit confidential or proprietary information, except solely at your own risk.</p>
                        <p class="fivehundred">3. Marketing restrictions</p>
                        <p>The distribution of information on this site may be restricted by law in certain countries. This site and the information on it is not addressed to any person resident in the territory or country or jurisdiction where such distribution would be contrary to local law or regulation. Some funds are not available, and offering materials relating to them will not be distributed, to persons resident in any country where such distribution would be contrary to local law or regulation.</p>
                        <p class="fivehundred">4. Compliance with law and regulation</p>
                        <p>When using our site, you agree you will comply with all applicable local, national and international laws and regulations, including those related to data privacy, international communications and exportation of technical or personal data. It may be illegal to view and/or download the information contained on our site in certain countries and we and our affiliates disclaim all responsibility if you access or download any information from our site in breach of any law or regulation of the country in which you are a citizen or in which you are resident or domiciled.</p>
                        <p class="fivehundred">5. Accuracy of information</p>
                        <p>We have taken reasonable care to ensure that the information on our site is accurate, current, complete, fit for its intended purpose and compliant with applicable law and regulation as at the date of issue. However, errors or omissions may occur due to circumstances beyond our control and no warranty is given, or representation made, regarding the accuracy, validity or completeness of the information on our site and no liability is accepted by such persons for the accuracy or completeness of such information. You must conduct your own due diligence and investigations rather than relying on any information on our site. Any person who acts upon, or changes his or her investment position in reliance on, the information contained on our site does so entirely at his or her own risk.</p>
                        <p class="fivehundred">6. Access</p>
                        <p>We reserve the right to deny you access to our site, or immediately to suspend or terminate your access to our site, or to disable any user name or password (whether chosen by you or allocated by us) at any time (in our sole discretion).</p>
                        <p class="fivehundred">7. Liability for use of our site</p>
                        <p>Access to our site is permitted on a temporary basis and we reserve the right to withdraw or amend the service and/or information we provide on our site without notice. We do not accept any liability if for any reason our site is unavailable at any time or for any period. Our site is provided for informational purposes only and is not intended to constitute an invitation, solicitation or offer to subscribe for or purchase any investments, products or services (including, for the avoidance of doubt, any units or shares in any fund), nor shall it, or the fact of its issuance, form the basis of, or be relied on in connection with, any contract.</p>
                        <p>The information and opinions contained in our site have been compiled, or arrived at, by us from sources believed by us to be reliable as at the date of publication. However, the information and any opinions contained on our site are subject to change, are for background purposes only and are not to be relied upon as investment advice or interpreted as a recommendation. We, our affiliates and other third parties connected to us do not accept any responsibility for any reliance which is placed by any visitor to our site, or by anyone who may be informed of any of its contents, on any information or opinions which are expressed herein and, to the extent permitted by law, expressly exclude all conditions, warranties, representations, undertakings and other terms which might otherwise be implied by statute, common law or in equity.</p>
                        <p>Our site is not intended to provide a sufficient basis on which to make any investment decision and you should not solely rely on it in evaluating the merits of investing in any investments, products or services referred to on our site. Any decision to purchase securities or interests with respect to any funds must only be made having thoroughly read the relevant prospectus or offering memorandum for the relevant fund, including any supplements thereto, which must be received and reviewed prior to any investment decision and which may contain information which is different from the information and opinions contained on our site. Any person subscribing for an investment, product or service referred to on our site must be able to bear the risks involved and must meet the suitability requirements relating to such investment, product or service. Some or all alternative investment programs may not be suitable for certain investors.</p>
                        <p>We, our affiliates and other third parties connected to us therefore expressly exclude any liability and responsibility arising from any reliance placed on such materials and information by any visitor to our site, or by anyone who may be informed of any of its contents, for (i) the accuracy, validity, timeliness, merchantability or completeness of, any information or data (whether prepared by us or by any third party) for any particular purpose or use or that the information or data will be free from error and (ii) any direct, indirect or consequential loss or damage of any kind incurred by any visitor to our site, or by anyone who may be informed of any of its contents, in connection with our site or in reliance on the information or opinions contained in it, or in connection with the use, inability to use, or results of the use of our site or any websites linked to it or materials posted on it. This does not affect our liability for any loss or damage which cannot be excluded or limited under applicable law.</p>
                        <p>The internet is not a completely reliable transmission medium and neither we nor any of our affiliates accept any liability for any data transmission errors such as data loss or damage or alteration of any kind or for the security or confidentiality of information transmitted across the internet to or from us or any of our affiliates. Any such transmission of information is entirely at your own risk.</p>
                        <p class="fivehundred">8. Linked websites</p>
                        <p>Should you leave our site via a link contained within our site and view content that is not provided by us, you do so at your own risk. We provide links only as a convenience and without liability. In particular, and without limitation, you understand and agree that the ability to link to another website and the fact that a link exists does not mean that we endorse or guarantee such site, the site sponsor or the site contents. The content which you access via such links will not have been developed, checked for accuracy, or otherwise reviewed by us. We make no guarantees or representations as to, and shall have no liability for, any electronic content delivered by any third party, including, without limitation, the accuracy, subject matter, quality, sequence or timeliness of any electronic content. Your reliance on any information contained on any site reached through a link shall be your own responsibility. When you leave our site, unless otherwise indicated, you will be subject to the terms of use and privacy policies of the new website which you are visiting. If you reached our site via a link, please be advised that the ability to link to our site and the fact that a link exists does not mean that we necessarily endorse, support, sanction, encourage, verify, or agree with the comments, opinions, or statements posted on the website from which you linked to our site (the ‘Linking Site’). We are not responsible for any damages or losses arising from any delays, defects or omissions that may exist in the services, information or other content provided in the Linking Site. We make no guarantee or representation as to, and shall have no liability for, any content on any Linking Site, including, without limitation, the accuracy, subject matter, quality, sequence or timeliness of any such content.</p>
                        <p class="fivehundred">9. Indemnity</p>
                        <p>You agree to indemnify, defend, and hold harmless us, our affiliates and our officers, partners, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including legal fees and costs, arising out of or in any way connected with your breach of the Terms of Use and/or access to or use of our site.</p>
                        <p class="fivehundred">10. Intellectual property rights</p>
                        <p>We are the owner or the licensee of all intellectual property rights in our site, and in the materials published on it. Those works are protected by laws and treaties around the world. All such rights are reserved. You may use the materials on our site and download and reproduce them in hard copy for your own reference only. Such materials may not otherwise be reproduced, distributed, stored in a data retrieval system or transmitted, in any form or by any means (electronic, mechanical, photocopying, recording or otherwise) without our prior written consent. Nothing on our site should be construed as granting any licence or right in relation to any of our trademarks or those of our affiliates or any third party.</p>
                        <p class="fivehundred">11. Severability</p>
                        <p>If any specific term or condition of these Terms of Use is determined by a court of competent jurisdiction to be invalid, void or unenforceable, that determination shall not affect the validity of the remaining provisions of these Terms of Use. Headings used in these Terms of Use are for convenience, are not substantive and shall not be used to interpret or construe any of the provisions contained in these Terms of Use. When used in these Terms of Use the words ‘includes’, and ‘including’ shall be deemed to be followed by the phrase ‘without limitation’.</p>
                        <p class="fivehundred">12. Material Interests</p>
                        <p>We and our partners, officers and/or employees may have holdings in the investment funds referred to on our site and may otherwise be interested in transactions that you effect in those funds.</p>
                        <p class="fivehundred">13. Governing Law</p>
                        <p>These Terms of Use and any non-contractual obligations arising from or connected with them shall be governed by and shall be construed in accordance with English law. English courts will have exclusive jurisdiction over any dispute arising from, or related to, use of our site (whether arising out of or in connection with contractual or non-contractual obligations) (‘<strong>proceedings</strong>’) and it is a condition of using our site that you waive any objection to proceedings in such courts on the grounds of venue or that proceedings have been brought in an inconvenient forum, although we retain the right to bring proceedings against you for breach of these Terms of Use in your country of residence or any other relevant country.</p>
                        <p class="fivehundred">14. Third parties</p>
                        <p>For EEA Users: we shall have the benefit of the rights conferred on us by these Terms of Use but otherwise no person who is not a party to these Terms of Use may enforce its terms under the Contracts (Rights of Third Parties) Act 1999.</p>
                        <p class="fivehundred">15. Entire agreement</p>
                        <p>These Terms of Use (incorporating our <a href="/privacy-and-cookies/">Cookies and Privacy Policy</a>) and our Disclaimer shall form the entire agreement between you and us in relation to your use of our site.</p>
                        <p>16. Contact us</p>
                        <p>If you have any enquiries in relation to our site or the information on it, please contact <a href="mailto:enquiries@brevanhoward.com">enquiries@brevanhoward.com</a>.</p>
                    </div>

                    <a class="disclaimer-agree close-reveal-modal button greenbutton tiny icon icon-tick" onClick={handleAccept}>I ACCEPT</a>
                    <a href="#" class="disclaimer-disagree-button button tiny icon icon-close" onClick={() => { setSubscribeDoNo(false) }}>I DO NOT ACCEPT</a>
                    <div class="panel">
                        <h5>COOKIE CONTROL</h5>
                        <p>This website uses cookies. By continuing to use this website you are giving consent to cookies being used. For information on cookies and how you can disable them visit our <a class="cookies-and-privacy-launch">Cookies &amp; Privacy policy.</a></p>
                    </div>
                </> : <>
                    <div id="disclaimer-disagree" class="reveal-modal open" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="" tabindex="0" aria-hidden="false" style={{ display: 'block', opacity: 1, visibility: 'visible', top: '100px' }}>
                        <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                        <h5>Thank you for visiting Brevan Howard.</h5>
                        <p>For further information contact:</p>
                        <h5>Investor Relations / Fund Enquiries: </h5>
                        <p><a href="mailto: ir@brevanhoward.com">ir@brevanhoward.com</a> </p>
                        <h5>All Other Enquiries: </h5>
                        <p><a href="mailto: enquiries@brevanhoward.com ">enquiries@brevanhoward.com</a> </p>
                        <h5>Press Enquiries: </h5>
                        <p>Peregrine Communications Group<br />
                            UK +44 20 3040 0874<br />
                            <a href="mailto:darius.athill@peregrinecommunications.com">Email Darius Athill</a><br />
                            USA +1 646 274 1420<br />
                            <a href="mailto:max.hilton@peregrinecommunications.com">Email Max Hilton</a></p></div>
                </>}
            </div>
            <div class="reveal-modal-bg" style={{ display: 'block' }}></div>
        </>
    )
}



export default Disclaimer;