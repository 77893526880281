import { sendRequest } from './request';



export const historyApi = () => {
    return sendRequest('/customers/history-action', 'GET');
};



