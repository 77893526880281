import { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registration } from './../../actions/customer'
import { useNavigate } from 'react-router-dom';
import { Input, message, Row } from "antd";


function RegisterPage({ registration, customer }) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        last_name: '',
        first_name: '',
        company: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        province: '',
        post_code: '',
        country: '',
    });
    const [errors, setErrors] = useState({
        email: '',
        password: '',
        last_name: '',
        first_name: '',
        company: '',
        address1: '',
        address2: '', // Необязательное поле
        address3: '', // Необязательное поле
        city: '',
        province: '',
        post_code: '',
        country: '',
    });



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const validateForm = () => {
        const newErrors = {};

        Object.keys(formData).forEach((field) => {
            if ((field !== 'address2' && field !== 'address3') && formData[field].trim() === '') {
                newErrors[field] = 'error';
            }
        });

        // Проверка совпадения паролей
        if (formData.password !== formData.password_repeat) {
            newErrors.password_repeat = 'error';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        if (validateForm()) {
            delete formData.password_repeat
            registration(formData)
        } else {
            message.error('Заполните все обязательные поля')
        }
    };

    useEffect(() => {
        if (customer.error) {
            message.error(customer.error);
        }
    }, [customer.error]);



    return (
        <body class="page-template page-template-page-request-access page-template-page-request-access-php page page-id-14 page-child parent-pageid-9">


            <div id="footer-wrapper" style={{ marginBottom: '-188px' }}>
                <div class="inner-wrap">
                    <div id="container">
                        <header class="header" role="banner">

                            <div class="sticky contain-to-grid">
                                <nav class="top-bar" data-topbar="" data-options="scrolltop: false" data-magellan-expedition="">
                                    <ul class="title-area">
                                        <li class="name">
                                            <a class="mainlogo" href="/"><img src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" /></a>
                                        </li>
                                        <li class="toggle-topbar menu-icon">
                                            <a href="#"><span></span></a>
                                        </li>
                                    </ul>

                                    <section class="top-bar-section">
                                        <ul id="menu-main-menu" class="top-bar-menu right"> <li id="menu-item-45" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-45"><a href="https://portal.brevanhoward.com/">Overview</a></li>
                                            <li id="menu-item-47" class="mobile-only menu-item menu-item-type-custom menu-item-object-custom menu-item-47" data-magellan-arrival="about"><a href="/#about">About</a></li>
                                            <li id="menu-item-48" class="mobile-only menu-item menu-item-type-custom menu-item-object-custom menu-item-48" data-magellan-arrival="locations"><a href="/#locations">Locations</a></li>
                                            <li id="menu-item-49" class="mobile-only menu-item menu-item-type-custom menu-item-object-custom menu-item-49" data-magellan-arrival="contact"><a href="/#contact">Contact</a></li>
                                        </ul>		</section></nav>
                            </div>

                        </header>


                        <div id="content">
                            <div id="inner-content" class="row">

                                <div class="large-12 medium-12 small-12 columns">
                                    <div class="hero-image">
                                        <h2>REQUEST ACCESS</h2>
                                    </div>
                                </div>

                                <Row>
                                    <div class="large-3 medium-3 columns show-for-medium-up left-bar">
                                        <div class="left-bar-container columns nopad-lr">
                                            <ul class="left-menu">
                                                <li><a href="/">INVESTOR LOGIN</a></li>
                                                <li class="active"><a href="/login/request-access">REQUEST ACCESS</a></li>
                                                <li><a href="/login/forgot-password/">FORGOT PASSWORD</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="large-9 medium-9 small-12 columns">
                                        {customer.statusResponseRegistration ? <>
                                            <div class="full-block-container">
                                                <div class="login-container">
                                                    <div className="row">
                                                        <div class="large-12 columns">

                                                            <h2>Регистрация</h2>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div class="large-12 columns">

                                                            <p>
                                                                Ваша заявка принята и обрабатывается менеджером
                                                            </p>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </> : <>
                                            <div class="full-block-container">
                                                <div class="login-container">

                                                    <span class="required-note">Fields marked with an asterisk (<span class="blue">*</span>) are mandatory</span>

                                                    <form>

                                                        <div class="row">

                                                            <div class="large-6 columns">
                                                                <label>
                                                                    <div class="bh-input-label">First Name <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.first_name} type="text" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="" className="bh-input required" id="tbxFirstName" />
                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxFirstName" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxFirstName"></div>
                                                            </div>

                                                            <div class="large-6 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Last Name <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.last_name} type="text" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="" className="bh-input required" id="tbxLastName" />

                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxLastName" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxLastName"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Email <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.email} type="email" name="email" value={formData.email} onChange={handleChange} placeholder="" className="bh-input required" id="tbxRequestEmail" />
                                                                    {/* <input type="email" placeholder="" class="bh-input required" id="tbxRequestEmail" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxRequestEmail"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Company <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.company} type="text" name="company" value={formData.company} onChange={handleChange} placeholder="" className="bh-input required" id="tbxCompany" />
                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxCompany" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxCompany"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Address Line 1 <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.address1} type="text" name="address1" value={formData.address1} onChange={handleChange} placeholder="" className="bh-input required" id="tbxAddr1" />

                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxAddr1" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxAddr1"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Address Line 2</div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.address2} type="text" name="address2" value={formData.address2} onChange={handleChange} placeholder="" className="bh-input" id="tbxAddr2" />
                                                                    {/* <input type="text" placeholder="" class="bh-input" id="tbxAddr2" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxAddr2"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Address Line 3</div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.address3} type="text" name="address3" value={formData.address3} onChange={handleChange} placeholder="" className="bh-input" id="tbxAddr3" />

                                                                    {/* <input type="text" placeholder="" class="bh-input" id="tbxAddr3" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxAddr3"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">City <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.city} type="text" name="city" value={formData.city} onChange={handleChange} placeholder="" className="bh-input required" id="tbxCity" />

                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxCity" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxCity"></div>
                                                            </div>

                                                            <div class="large-6 columns">
                                                                <label>
                                                                    <div class="bh-input-label">State / Province / County <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.province} type="text" name="province" value={formData.province} onChange={handleChange} placeholder="" className="bh-input required" id="tbxState" />

                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxState" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxState"></div>
                                                            </div>

                                                            <div class="large-6 columns">
                                                                <label>
                                                                    <div class="bh-input-label">ZIP / Post Code<span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <Input status={errors.post_code} type="text" name="post_code" value={formData.post_code} onChange={handleChange} placeholder="" className="bh-input required" id="tbxPostCode" />

                                                                    {/* <input type="text" placeholder="" class="bh-input required" id="tbxPostCode" /> */}
                                                                </div>
                                                                <div class="bh-validation" related="tbxPostCode"></div>
                                                            </div>

                                                            <div class="large-12 columns">
                                                                <label>
                                                                    <div class="bh-input-label">Country <span class="blue">*</span></div>
                                                                </label>
                                                                <div class="bh-input">
                                                                    <select name="country" class="bh-input" id="ddlCountry" value={formData.country} onChange={handleChange}>
                                                                        <option value="" disabled="disabled" selected="selected"></option>
                                                                        <option value="GB">United Kingdom</option>
                                                                        <option value="US">United States</option>
                                                                        <option value="" disabled="disabled">_____________</option>
                                                                        <option value="AF">Afghanistan</option>
                                                                        <option value="AX">Åland Islands</option>
                                                                        <option value="AL">Albania</option>
                                                                        <option value="DZ">Algeria</option>
                                                                        <option value="AS">American Samoa</option>
                                                                        <option value="AD">Andorra</option>
                                                                        <option value="AO">Angola</option>
                                                                        <option value="AI">Anguilla</option>
                                                                        <option value="AQ">Antarctica</option>
                                                                        <option value="AG">Antigua and Barbuda</option>
                                                                        <option value="AR">Argentina</option>
                                                                        <option value="AM">Armenia</option>
                                                                        <option value="AW">Aruba</option>
                                                                        <option value="AU">Australia</option>
                                                                        <option value="AT">Austria</option>
                                                                        <option value="AZ">Azerbaijan</option>
                                                                        <option value="BS">Bahamas</option>
                                                                        <option value="BH">Bahrain</option>
                                                                        <option value="BD">Bangladesh</option>
                                                                        <option value="BB">Barbados</option>
                                                                        <option value="BY">Belarus</option>
                                                                        <option value="BE">Belgium</option>
                                                                        <option value="BZ">Belize</option>
                                                                        <option value="BJ">Benin</option>
                                                                        <option value="BM">Bermuda</option>
                                                                        <option value="BT">Bhutan</option>
                                                                        <option value="BO">Bolivia, Plurinational State of</option>
                                                                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                                                        <option value="BA">Bosnia and Herzegovina</option>
                                                                        <option value="BW">Botswana</option>
                                                                        <option value="BV">Bouvet Island</option>
                                                                        <option value="BR">Brazil</option>
                                                                        <option value="IO">British Indian Ocean Territory</option>
                                                                        <option value="BN">Brunei Darussalam</option>
                                                                        <option value="BG">Bulgaria</option>
                                                                        <option value="BF">Burkina Faso</option>
                                                                        <option value="BI">Burundi</option>
                                                                        <option value="KH">Cambodia</option>
                                                                        <option value="CM">Cameroon</option>
                                                                        <option value="CA">Canada</option>
                                                                        <option value="CV">Cape Verde</option>
                                                                        <option value="KY">Cayman Islands</option>
                                                                        <option value="CF">Central African Republic</option>
                                                                        <option value="TD">Chad</option>
                                                                        <option value="CL">Chile</option>
                                                                        <option value="CN">China</option>
                                                                        <option value="CX">Christmas Island</option>
                                                                        <option value="CC">Cocos (Keeling) Islands</option>
                                                                        <option value="CO">Colombia</option>
                                                                        <option value="KM">Comoros</option>
                                                                        <option value="CG">Congo</option>
                                                                        <option value="CD">Congo, the Democratic Republic of the</option>
                                                                        <option value="CK">Cook Islands</option>
                                                                        <option value="CR">Costa Rica</option>
                                                                        <option value="CI">Côte d'Ivoire</option>
                                                                        <option value="HR">Croatia</option>
                                                                        <option value="CU">Cuba</option>
                                                                        <option value="CW">Curaçao</option>
                                                                        <option value="CY">Cyprus</option>
                                                                        <option value="CZ">Czech Republic</option>
                                                                        <option value="DK">Denmark</option>
                                                                        <option value="DJ">Djibouti</option>
                                                                        <option value="DM">Dominica</option>
                                                                        <option value="DO">Dominican Republic</option>
                                                                        <option value="EC">Ecuador</option>
                                                                        <option value="EG">Egypt</option>
                                                                        <option value="SV">El Salvador</option>
                                                                        <option value="GQ">Equatorial Guinea</option>
                                                                        <option value="ER">Eritrea</option>
                                                                        <option value="EE">Estonia</option>
                                                                        <option value="ET">Ethiopia</option>
                                                                        <option value="FK">Falkland Islands (Malvinas)</option>
                                                                        <option value="FO">Faroe Islands</option>
                                                                        <option value="FJ">Fiji</option>
                                                                        <option value="FI">Finland</option>
                                                                        <option value="FR">France</option>
                                                                        <option value="GF">French Guiana</option>
                                                                        <option value="PF">French Polynesia</option>
                                                                        <option value="TF">French Southern Territories</option>
                                                                        <option value="GA">Gabon</option>
                                                                        <option value="GM">Gambia</option>
                                                                        <option value="GE">Georgia</option>
                                                                        <option value="DE">Germany</option>
                                                                        <option value="GH">Ghana</option>
                                                                        <option value="GI">Gibraltar</option>
                                                                        <option value="GR">Greece</option>
                                                                        <option value="GL">Greenland</option>
                                                                        <option value="GD">Grenada</option>
                                                                        <option value="GP">Guadeloupe</option>
                                                                        <option value="GU">Guam</option>
                                                                        <option value="GT">Guatemala</option>
                                                                        <option value="GG">Guernsey</option>
                                                                        <option value="GN">Guinea</option>
                                                                        <option value="GW">Guinea-Bissau</option>
                                                                        <option value="GY">Guyana</option>
                                                                        <option value="HT">Haiti</option>
                                                                        <option value="HM">Heard Island and McDonald Islands</option>
                                                                        <option value="VA">Holy See (Vatican City State)</option>
                                                                        <option value="HN">Honduras</option>
                                                                        <option value="HK">Hong Kong</option>
                                                                        <option value="HU">Hungary</option>
                                                                        <option value="IS">Iceland</option>
                                                                        <option value="IN">India</option>
                                                                        <option value="ID">Indonesia</option>
                                                                        <option value="IR">Iran, Islamic Republic of</option>
                                                                        <option value="IQ">Iraq</option>
                                                                        <option value="IE">Ireland</option>
                                                                        <option value="IM">Isle of Man</option>
                                                                        <option value="IL">Israel</option>
                                                                        <option value="IT">Italy</option>
                                                                        <option value="JM">Jamaica</option>
                                                                        <option value="JP">Japan</option>
                                                                        <option value="JE">Jersey</option>
                                                                        <option value="JO">Jordan</option>
                                                                        <option value="KZ">Kazakhstan</option>
                                                                        <option value="KE">Kenya</option>
                                                                        <option value="KI">Kiribati</option>
                                                                        <option value="KP">Korea, Democratic People's Republic of</option>
                                                                        <option value="KR">Korea, Republic of</option>
                                                                        <option value="KW">Kuwait</option>
                                                                        <option value="KG">Kyrgyzstan</option>
                                                                        <option value="LA">Lao People's Democratic Republic</option>
                                                                        <option value="LV">Latvia</option>
                                                                        <option value="LB">Lebanon</option>
                                                                        <option value="LS">Lesotho</option>
                                                                        <option value="LR">Liberia</option>
                                                                        <option value="LY">Libya</option>
                                                                        <option value="LI">Liechtenstein</option>
                                                                        <option value="LT">Lithuania</option>
                                                                        <option value="LU">Luxembourg</option>
                                                                        <option value="MO">Macao</option>
                                                                        <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                                                        <option value="MG">Madagascar</option>
                                                                        <option value="MW">Malawi</option>
                                                                        <option value="MY">Malaysia</option>
                                                                        <option value="MV">Maldives</option>
                                                                        <option value="ML">Mali</option>
                                                                        <option value="MT">Malta</option>
                                                                        <option value="MH">Marshall Islands</option>
                                                                        <option value="MQ">Martinique</option>
                                                                        <option value="MR">Mauritania</option>
                                                                        <option value="MU">Mauritius</option>
                                                                        <option value="YT">Mayotte</option>
                                                                        <option value="MX">Mexico</option>
                                                                        <option value="FM">Micronesia, Federated States of</option>
                                                                        <option value="MD">Moldova, Republic of</option>
                                                                        <option value="MC">Monaco</option>
                                                                        <option value="MN">Mongolia</option>
                                                                        <option value="ME">Montenegro</option>
                                                                        <option value="MS">Montserrat</option>
                                                                        <option value="MA">Morocco</option>
                                                                        <option value="MZ">Mozambique</option>
                                                                        <option value="MM">Myanmar</option>
                                                                        <option value="NA">Namibia</option>
                                                                        <option value="NR">Nauru</option>
                                                                        <option value="NP">Nepal</option>
                                                                        <option value="NL">Netherlands</option>
                                                                        <option value="NC">New Caledonia</option>
                                                                        <option value="NZ">New Zealand</option>
                                                                        <option value="NI">Nicaragua</option>
                                                                        <option value="NE">Niger</option>
                                                                        <option value="NG">Nigeria</option>
                                                                        <option value="NU">Niue</option>
                                                                        <option value="NF">Norfolk Island</option>
                                                                        <option value="MP">Northern Mariana Islands</option>
                                                                        <option value="NO">Norway</option>
                                                                        <option value="OM">Oman</option>
                                                                        <option value="PK">Pakistan</option>
                                                                        <option value="PW">Palau</option>
                                                                        <option value="PS">Palestinian Territory, Occupied</option>
                                                                        <option value="PA">Panama</option>
                                                                        <option value="PG">Papua New Guinea</option>
                                                                        <option value="PY">Paraguay</option>
                                                                        <option value="PE">Peru</option>
                                                                        <option value="PH">Philippines</option>
                                                                        <option value="PN">Pitcairn</option>
                                                                        <option value="PL">Poland</option>
                                                                        <option value="PT">Portugal</option>
                                                                        <option value="PR">Puerto Rico</option>
                                                                        <option value="QA">Qatar</option>
                                                                        <option value="RE">Réunion</option>
                                                                        <option value="RO">Romania</option>
                                                                        <option value="RU">Russian Federation</option>
                                                                        <option value="RW">Rwanda</option>
                                                                        <option value="BL">Saint Barthélemy</option>
                                                                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                                                        <option value="KN">Saint Kitts and Nevis</option>
                                                                        <option value="LC">Saint Lucia</option>
                                                                        <option value="MF">Saint Martin (French part)</option>
                                                                        <option value="PM">Saint Pierre and Miquelon</option>
                                                                        <option value="VC">Saint Vincent and the Grenadines</option>
                                                                        <option value="WS">Samoa</option>
                                                                        <option value="SM">San Marino</option>
                                                                        <option value="ST">Sao Tome and Principe</option>
                                                                        <option value="SA">Saudi Arabia</option>
                                                                        <option value="SN">Senegal</option>
                                                                        <option value="RS">Serbia</option>
                                                                        <option value="SC">Seychelles</option>
                                                                        <option value="SL">Sierra Leone</option>
                                                                        <option value="SG">Singapore</option>
                                                                        <option value="SX">Sint Maarten (Dutch part)</option>
                                                                        <option value="SK">Slovakia</option>
                                                                        <option value="SI">Slovenia</option>
                                                                        <option value="SB">Solomon Islands</option>
                                                                        <option value="SO">Somalia</option>
                                                                        <option value="ZA">South Africa</option>
                                                                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                                                                        <option value="SS">South Sudan</option>
                                                                        <option value="ES">Spain</option>
                                                                        <option value="LK">Sri Lanka</option>
                                                                        <option value="SD">Sudan</option>
                                                                        <option value="SR">Suriname</option>
                                                                        <option value="SJ">Svalbard and Jan Mayen</option>
                                                                        <option value="SZ">Swaziland</option>
                                                                        <option value="SE">Sweden</option>
                                                                        <option value="CH">Switzerland</option>
                                                                        <option value="SY">Syrian Arab Republic</option>
                                                                        <option value="TW">Taiwan, Province of China</option>
                                                                        <option value="TJ">Tajikistan</option>
                                                                        <option value="TZ">Tanzania, United Republic of</option>
                                                                        <option value="TH">Thailand</option>
                                                                        <option value="TL">Timor-Leste</option>
                                                                        <option value="TG">Togo</option>
                                                                        <option value="TK">Tokelau</option>
                                                                        <option value="TO">Tonga</option>
                                                                        <option value="TT">Trinidad and Tobago</option>
                                                                        <option value="TN">Tunisia</option>
                                                                        <option value="TR">Turkey</option>
                                                                        <option value="TM">Turkmenistan</option>
                                                                        <option value="TC">Turks and Caicos Islands</option>
                                                                        <option value="TV">Tuvalu</option>
                                                                        <option value="UG">Uganda</option>
                                                                        <option value="UA">Ukraine</option>
                                                                        <option value="AE">United Arab Emirates</option>
                                                                        <option value="UM">United States Minor Outlying Islands</option>
                                                                        <option value="UY">Uruguay</option>
                                                                        <option value="UZ">Uzbekistan</option>
                                                                        <option value="VU">Vanuatu</option>
                                                                        <option value="VE">Venezuela, Bolivarian Republic of</option>
                                                                        <option value="VN">Viet Nam</option>
                                                                        <option value="VG">Virgin Islands, British</option>
                                                                        <option value="VI">Virgin Islands, U.S.</option>
                                                                        <option value="WF">Wallis and Futuna</option>
                                                                        <option value="EH">Western Sahara</option>
                                                                        <option value="YE">Yemen</option>
                                                                        <option value="ZM">Zambia</option>
                                                                        <option value="ZW">Zimbabwe</option>
                                                                    </select>
                                                                </div>
                                                                <div class="bh-validation" related="ddlCountry"></div>

                                                            </div>
                                                            <div className="large-6 columns">
                                                                <label>
                                                                    <div className="bh-input-label">Password</div>
                                                                </label>
                                                                <div className="bh-input">
                                                                    <Input status={errors.password} type="password" name="password" value={formData.password} onChange={handleChange} placeholder="" className="bh-input required" id="tbxPostCode" />
                                                                </div>
                                                                <div className="bh-validation" related="tbxPostCode"></div>
                                                            </div>
                                                            <div className="large-6 columns">
                                                                <label>
                                                                    <div className="bh-input-label">Repeat password</div>
                                                                </label>
                                                                <div className="bh-input">
                                                                    <Input status={errors.password_repeat} type="password" name="password_repeat" value={formData.password_repeat} onChange={handleChange} placeholder="" className="bh-input required" id="tbxPostCode" />
                                                                </div>
                                                                <div className="bh-validation" related="tbxPostCode"></div>
                                                            </div>
                                                            <div class="bh-login-success" style={{ display: 'none' }}></div>
                                                            <div class="bh-login-error" style={{ display: 'none' }}></div>
                                                        </div>

                                                        <div class="row end-of-form">

                                                            <div class="large-12 medium-12 small-12 columns">
                                                                <input onClick={handleSubmit} id="btnSendRequest" class="right button tiny" type="button" value="SUBMIT" />
                                                            </div>

                                                        </div>

                                                    </form>

                                                </div>
                                            </div>
                                        </>}

                                    </div>
                                </Row>

                            </div>
                        </div>


                    </div>
                </div>
                <div class="push" style={{ height: '188px' }}></div>
            </div>

            <footer class="footer" role="contentinfo">
                <div id="inner-footer" class="row">

                    <div class="large-12 medium-12 small-12 columns">
                        <ul class="footerlinks inline-list">
                            <li><a href="/">OVERVIEW</a></li>
                            <li><a href="/login/request-access/">REQUEST ACCESS</a></li>
                            <li><a href="/disclaimer">DISCLAIMER</a></li>
                            <li><a href="/cookies-and-privacy">COOKIES &amp; PRIVACY POLICY</a></li>
                            <li><a href="/notices">NOTICES</a></li>
                            <li><a href="/sitemap">SITEMAP</a></li>

                        </ul>
                    </div>

                    <div class="large-12 medium-12 columns">

                        <p class="copyright">Data as 1 May 2020. Source: Brevan Howard Capital Management LP.<br />© 2024 Brevan Howard Asset Management LLP. <a class="peregrinelink" href="http://www.peregrinecommunications.com" target="_blank">Website by Peregrine Communications.</a></p>

                    </div>
                </div></footer>



            <div id="welcome-disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h2>WELCOME</h2>
                <h5>Before Proceeding, please select your location.</h5>
                <a class="button tiny icon icon-right welcome-disclaimer-other">Other</a>
                <a class="button tiny icon icon-right welcome-disclaimer-usa">USA</a>
            </div>



            <div id="disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h2>DISCLAIMER</h2>
                <h5>Please read this page before proceeding, as it explains certain restrictions imposed by law on the distribution of this information and the countries in which our funds are authorised for sale. It is your responsibility to be aware of and to observe all applicable laws and regulations of any relevant jurisdiction.</h5>

                <div class="terms-container">
                    <p><strong>TERMS OF USE</strong></p>
                    <p>FOR EEA USERS: This site and the information on it is neither directed at nor made available to retail clients. It is directed only at persons who are professional investors (for the purposes of the Alternative Investment Fund Managers Directive (2011/61/EU) (known as ‘AIFMD’); professional clients or eligible counterparties for the purposes of the Markets in Financial Instruments Directive (Directive 2004/39/EC) (known as ‘MiFID’); or as otherwise defined under applicable local regulations and at whom this site and the information on it may lawfully be directed in any relevant jurisdiction. The definition of professional investor under AIFMD can be found <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32011L0061&amp;from=EN">here</a> and the definitions of professional client and eligible counterparty under MiFID can be found <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:02004L0039-20110104&amp;from=EN">here</a>. Please contact us if you require any further information on your status.</p>
                    <p>FOR HONG KONG USERS: This site and the information on it is directed only at, and made available only to, professional investors as defined in the Securities and Futures Ordinance (Cap. 571, the laws of Hong Kong, “the Ordinance”) and its subsidiary legislation, Securities and Futures (Professional Investor) Rules (“PI Rules”).&nbsp; Any investment/subscription from a Hong Kong investor will only be accepted if such investor is a professional investor. Please refer to the links for the definitions of professional investors under the <a href="http://www.legislation.gov.hk/blis_pdf.nsf/6799165D2FEE3FA94825755E0033E532/5167961DDC96C3B7482575EF001C7C2D/$FILE/CAP_571_e_b5.pdf">Ordinance</a> and <a href="http://www.legislation.gov.hk/blis_pdf.nsf/6799165D2FEE3FA94825755E0033E532/7E7E9E6B95063DE1482575EF001D1A93/$FILE/CAP_571D_e_b5.pdf">PI Rules</a>. Please contact us if you require any further information on your status.</p>
                    <p>By accepting these Terms of Use, you hereby certify that you are a Professional Investor, Professional Client or an Eligible Counterparty.</p>
                    <p class="fivehundred">1. About Brevan Howard</p>
                    <p>The Brevan Howard website ‘portal.brevanhoward.com’ (‘our site’) is issued for all purposes by Brevan Howard Capital Management LP (‘<strong>BHCM LP</strong>’), acting by its sole general partner, Brevan Howard Capital Management Limited (‘<strong>BHCML</strong>’) (and the expression ‘<strong>we</strong>’ and ‘<strong>our</strong>’ shall be construed accordingly). We are registered with the Jersey Financial Services Commission under the Financial Services (Jersey) Law, 1998 to carry on fund services business as a manager, investment manager and distributor. We, in such capacity, have appointed certain of our affiliates, including Brevan Howard Asset Management LLP (‘<strong>BHAM</strong>’), to act as investment manager of, and provide certain related services to, certain funds. BHAM is authorised and regulated by the Financial Conduct Authority of the United Kingdom (‘FCA’) (firm reference number: 216851). BHCML (acting as sole general partner of BHCM LP) and each of its affiliates are collectively referred to in this site as ‘Brevan Howard’ and references to ‘Brevan Howard’ are to BHCML (acting as sole general partner of BHCM LP) and/or its relevant affiliate.</p>
                    <p>2. Your use of our site</p>
                    <p>These Terms of Use set out the terms on which you may make use of our site and apply to every page on our site, whether or not such pages are individually disclaimed. Please read these Terms of Use carefully before you start to use our site. By using our site, you have indicated that you accept these Terms of Use and that you agree to abide by them. If you do not agree to these Terms of Use, please refrain from using our site. These Terms of Use also incorporate our <a href="https://p1web01v/cookies-and-privacy/">Cookies and Privacy Policy</a>, and all other rules, policies and procedures that may be published from time to time on our site, each of which is incorporated by reference and each of which may be updated by us from time to time without notice to you.</p>
                    <p>In the United Kingdom, the issue and communication of this website has been approved by BHAM. If you are in the United Kingdom or elsewhere in the EEA, you should note that our site may contain information or documents which constitute a financial promotion for the purposes of the Financial Services and Markets Act 2000 and the FCA Rules. Accordingly, our site is issued only to and/or is directed only at persons who are professional clients or eligible counterparties as defined in the FCA Rules. We neither provide investment advice to, nor receive and transmit orders from, investors in any funds nor do we carry on any other activities with or for such investors that constitute ‘MiFID or equivalent third country business’ for the purposes of the FCA Rules.</p>
                    <p>We may revise these Terms of Use from time to time by updating this page. The revised Terms of Use will take effect when they are posted. You are expected to check this page from time to time and to take notice of any changes we may have made, as by using our site you acknowledge that you have accepted any such amendments.</p>
                    <p>You must <strong>NOT</strong> use our site (or permit or procure others to use it) as follows:</p>
                    <p>(A) &nbsp; for any unlawful, improper or illegal purpose or activity;<br />
                        (B) &nbsp;&nbsp;to violate our or any third party’s copyright, trademark, proprietary or other intellectual property rights;<br />
                        (C) &nbsp;&nbsp;to damage our name or reputation or that of our affiliates or any third parties;<br />
                        (D) &nbsp;to impersonate any of our employees or other person or use a false name while using our site or implying an association with us;<br />
                        (E) &nbsp;&nbsp;to penetrate our security measures or other entities’ systems (“hacking”);<br />
                        (F) &nbsp;&nbsp;to generate excessive amounts of internet traffic, to interfere with our network or other’s use of our site or to engage in activities designed to or having the effect of degrading or denying service to users of our site or others;<br />
                        (G) &nbsp;&nbsp;to introduce viruses, worms, harmful code and/or Trojan horses onto the internet or into our site or any other entity’s systems and it is your responsibility to ensure that whatever you download or select for your use from our site is free from such items; and/or<br />
                        (H) &nbsp;&nbsp;to transmit confidential or proprietary information, except solely at your own risk.</p>
                    <p class="fivehundred">3. Marketing restrictions</p>
                    <p>The distribution of information on this site may be restricted by law in certain countries. This site and the information on it is not addressed to any person resident in the territory or country or jurisdiction where such distribution would be contrary to local law or regulation. Some funds are not available, and offering materials relating to them will not be distributed, to persons resident in any country where such distribution would be contrary to local law or regulation.</p>
                    <p class="fivehundred">4. Compliance with law and regulation</p>
                    <p>When using our site, you agree you will comply with all applicable local, national and international laws and regulations, including those related to data privacy, international communications and exportation of technical or personal data. It may be illegal to view and/or download the information contained on our site in certain countries and we and our affiliates disclaim all responsibility if you access or download any information from our site in breach of any law or regulation of the country in which you are a citizen or in which you are resident or domiciled.</p>
                    <p class="fivehundred">5. Accuracy of information</p>
                    <p>We have taken reasonable care to ensure that the information on our site is accurate, current, complete, fit for its intended purpose and compliant with applicable law and regulation as at the date of issue. However, errors or omissions may occur due to circumstances beyond our control and no warranty is given, or representation made, regarding the accuracy, validity or completeness of the information on our site and no liability is accepted by such persons for the accuracy or completeness of such information. You must conduct your own due diligence and investigations rather than relying on any information on our site. Any person who acts upon, or changes his or her investment position in reliance on, the information contained on our site does so entirely at his or her own risk.</p>
                    <p class="fivehundred">6. Access</p>
                    <p>We reserve the right to deny you access to our site, or immediately to suspend or terminate your access to our site, or to disable any user name or password (whether chosen by you or allocated by us) at any time (in our sole discretion).</p>
                    <p class="fivehundred">7. Liability for use of our site</p>
                    <p>Access to our site is permitted on a temporary basis and we reserve the right to withdraw or amend the service and/or information we provide on our site without notice. We do not accept any liability if for any reason our site is unavailable at any time or for any period. Our site is provided for informational purposes only and is not intended to constitute an invitation, solicitation or offer to subscribe for or purchase any investments, products or services (including, for the avoidance of doubt, any units or shares in any fund), nor shall it, or the fact of its issuance, form the basis of, or be relied on in connection with, any contract.</p>
                    <p>The information and opinions contained in our site have been compiled, or arrived at, by us from sources believed by us to be reliable as at the date of publication. However, the information and any opinions contained on our site are subject to change, are for background purposes only and are not to be relied upon as investment advice or interpreted as a recommendation. We, our affiliates and other third parties connected to us do not accept any responsibility for any reliance which is placed by any visitor to our site, or by anyone who may be informed of any of its contents, on any information or opinions which are expressed herein and, to the extent permitted by law, expressly exclude all conditions, warranties, representations, undertakings and other terms which might otherwise be implied by statute, common law or in equity.</p>
                    <p>Our site is not intended to provide a sufficient basis on which to make any investment decision and you should not solely rely on it in evaluating the merits of investing in any investments, products or services referred to on our site. Any decision to purchase securities or interests with respect to any funds must only be made having thoroughly read the relevant prospectus or offering memorandum for the relevant fund, including any supplements thereto, which must be received and reviewed prior to any investment decision and which may contain information which is different from the information and opinions contained on our site. Any person subscribing for an investment, product or service referred to on our site must be able to bear the risks involved and must meet the suitability requirements relating to such investment, product or service. Some or all alternative investment programs may not be suitable for certain investors.</p>
                    <p>We, our affiliates and other third parties connected to us therefore expressly exclude any liability and responsibility arising from any reliance placed on such materials and information by any visitor to our site, or by anyone who may be informed of any of its contents, for (i) the accuracy, validity, timeliness, merchantability or completeness of, any information or data (whether prepared by us or by any third party) for any particular purpose or use or that the information or data will be free from error and (ii) any direct, indirect or consequential loss or damage of any kind incurred by any visitor to our site, or by anyone who may be informed of any of its contents, in connection with our site or in reliance on the information or opinions contained in it, or in connection with the use, inability to use, or results of the use of our site or any websites linked to it or materials posted on it. This does not affect our liability for any loss or damage which cannot be excluded or limited under applicable law.</p>
                    <p>The internet is not a completely reliable transmission medium and neither we nor any of our affiliates accept any liability for any data transmission errors such as data loss or damage or alteration of any kind or for the security or confidentiality of information transmitted across the internet to or from us or any of our affiliates. Any such transmission of information is entirely at your own risk.</p>
                    <p class="fivehundred">8. Linked websites</p>
                    <p>Should you leave our site via a link contained within our site and view content that is not provided by us, you do so at your own risk. We provide links only as a convenience and without liability. In particular, and without limitation, you understand and agree that the ability to link to another website and the fact that a link exists does not mean that we endorse or guarantee such site, the site sponsor or the site contents. The content which you access via such links will not have been developed, checked for accuracy, or otherwise reviewed by us. We make no guarantees or representations as to, and shall have no liability for, any electronic content delivered by any third party, including, without limitation, the accuracy, subject matter, quality, sequence or timeliness of any electronic content. Your reliance on any information contained on any site reached through a link shall be your own responsibility. When you leave our site, unless otherwise indicated, you will be subject to the terms of use and privacy policies of the new website which you are visiting. If you reached our site via a link, please be advised that the ability to link to our site and the fact that a link exists does not mean that we necessarily endorse, support, sanction, encourage, verify, or agree with the comments, opinions, or statements posted on the website from which you linked to our site (the ‘Linking Site’). We are not responsible for any damages or losses arising from any delays, defects or omissions that may exist in the services, information or other content provided in the Linking Site. We make no guarantee or representation as to, and shall have no liability for, any content on any Linking Site, including, without limitation, the accuracy, subject matter, quality, sequence or timeliness of any such content.</p>
                    <p class="fivehundred">9. Indemnity</p>
                    <p>You agree to indemnify, defend, and hold harmless us, our affiliates and our officers, partners, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including legal fees and costs, arising out of or in any way connected with your breach of the Terms of Use and/or access to or use of our site.</p>
                    <p class="fivehundred">10. Intellectual property rights</p>
                    <p>We are the owner or the licensee of all intellectual property rights in our site, and in the materials published on it. Those works are protected by laws and treaties around the world. All such rights are reserved. You may use the materials on our site and download and reproduce them in hard copy for your own reference only. Such materials may not otherwise be reproduced, distributed, stored in a data retrieval system or transmitted, in any form or by any means (electronic, mechanical, photocopying, recording or otherwise) without our prior written consent. Nothing on our site should be construed as granting any licence or right in relation to any of our trademarks or those of our affiliates or any third party.</p>
                    <p class="fivehundred">11. Severability</p>
                    <p>If any specific term or condition of these Terms of Use is determined by a court of competent jurisdiction to be invalid, void or unenforceable, that determination shall not affect the validity of the remaining provisions of these Terms of Use. Headings used in these Terms of Use are for convenience, are not substantive and shall not be used to interpret or construe any of the provisions contained in these Terms of Use. When used in these Terms of Use the words ‘includes’, and ‘including’ shall be deemed to be followed by the phrase ‘without limitation’.</p>
                    <p class="fivehundred">12. Material Interests</p>
                    <p>We and our partners, officers and/or employees may have holdings in the investment funds referred to on our site and may otherwise be interested in transactions that you effect in those funds.</p>
                    <p class="fivehundred">13. Governing Law</p>
                    <p>These Terms of Use and any non-contractual obligations arising from or connected with them shall be governed by and shall be construed in accordance with English law. English courts will have exclusive jurisdiction over any dispute arising from, or related to, use of our site (whether arising out of or in connection with contractual or non-contractual obligations) (‘<strong>proceedings</strong>’) and it is a condition of using our site that you waive any objection to proceedings in such courts on the grounds of venue or that proceedings have been brought in an inconvenient forum, although we retain the right to bring proceedings against you for breach of these Terms of Use in your country of residence or any other relevant country.</p>
                    <p class="fivehundred">14. Third parties</p>
                    <p>For EEA Users: we shall have the benefit of the rights conferred on us by these Terms of Use but otherwise no person who is not a party to these Terms of Use may enforce its terms under the Contracts (Rights of Third Parties) Act 1999.</p>
                    <p class="fivehundred">15. Entire agreement</p>
                    <p>These Terms of Use (incorporating our <a href="/privacy-and-cookies/">Cookies and Privacy Policy</a>) and our Disclaimer shall form the entire agreement between you and us in relation to your use of our site.</p>
                    <p>16. Contact us</p>
                    <p>If you have any enquiries in relation to our site or the information on it, please contact <a href="mailto:enquiries@brevanhoward.com">enquiries@brevanhoward.com</a>.</p>
                </div>

                <a class="disclaimer-agree close-reveal-modal button greenbutton tiny icon icon-tick">I ACCEPT</a>
                <a href="#" class="disclaimer-disagree-button button tiny icon icon-close">I DO NOT ACCEPT</a>
                <div class="panel">
                    <h5>COOKIE CONTROL</h5>
                    <p>This website uses cookies. By continuing to use this website you are giving consent to cookies being used. For information on cookies and how you can disable them visit our <a class="cookies-and-privacy-launch">Cookies &amp; Privacy policy.</a></p>
                </div>
            </div>



            <div id="disclaimer-disagree" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h5>Thank you for visiting Brevan Howard.</h5>
                <p>For further information contact:</p>
                <h5>Investor Relations / Fund Enquiries: </h5>
                <p><a href="mailto: ir@brevanhoward.com">ir@brevanhoward.com</a> </p>
                <h5>All Other Enquiries: </h5>
                <p><a href="mailto: enquiries@brevanhoward.com ">enquiries@brevanhoward.com</a> </p>
                <h5>Press Enquiries: </h5>
                <p>Peregrine Communications Group<br />
                    UK +44 20 3040 0874<br />
                    <a href="mailto:darius.athill@peregrinecommunications.com">Email Darius Athill</a><br />
                    USA +1 646 274 1420<br />
                    <a href="mailto:max.hilton@peregrinecommunications.com">Email Max Hilton</a></p></div>



            <div id="usa-disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <h5>Access to the information contained in this website is restricted under applicable securities laws in the United States. The information contained in this website, therefore, generally is not available to United States persons.</h5>
                <p>For further information contact:</p>
                <h5>Investor Relations / Fund Enquiries: </h5>
                <p><a href="mailto: ir@brevanhoward.com">ir@brevanhoward.com</a> </p>
                <h5>All Other Enquiries: </h5>
                <p><a href="mailto: enquiries@brevanhoward.com ">enquiries@brevanhoward.com</a> </p>
                <h5>Press Enquiries: </h5>
                <p>Peregrine Communications Group<br />
                    UK +44 20 3040 0874<br />
                    <a href="mailto:darius.athill@peregrinecommunications.com">Email Darius Athill</a><br />
                    USA +1 646 274 1420<br />
                    <a href="mailto:max.hilton@peregrinecommunications.com">Email Max Hilton</a></p></div>


            <div id="cookies-and-privacy-disclaimer" class="reveal-modal" data-options="close_on_background_click:false; close_on_esc:false;" data-reveal="">
                <img class="disclaimer-logo" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/images/main-logo-new.svg" />
                <p><a href="#" class="cookies-back">&lt; Back</a></p>
                <h5>COOKIES AND PRIVACY POLICY</h5>
                <div class="cookies-terms-container">
                    <p class="threehundred">This Cookies and Privacy Policy describes how we handle your personal information on our site. It applies generally to our site. In addition to the privacy practices set out in this Cookies and Privacy Policy, we have <a href="https://www.brevanhoward.com/disclaimer/">Terms of Use</a> which incorporate the terms of this Cookies and Privacy Policy. Some words used in this Privacy Policy are defined in our Terms of Use.</p>
                    <p class="fivehundred">1. What are cookies?</p>
                    <p class="threehundred">Cookies are small text files which are placed on the computer of a website user to provide the website operator with information about use of our site. They are used to enable websites to function or to provide information to the owners of a website.</p>
                    <p class="threehundred">Some cookies are deleted when you close down your browser. These are known as session cookies. Others remain on your device until they expire or you delete them from your cache. These are known as persistent cookies and enable us to remember things about you as a returning visitor.</p>
                    <p class="threehundred">To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener">www.allaboutcookies.org</a>. Alternatively, you can search the internet for other independent information on cookies.</p>
                    <p class="fivehundred">2. Why do we use cookies?</p>
                    <p class="threehundred">In broad terms we use cookies on our site for the following purposes:</p>
                    <ul>
                        <li><strong>Analytical purposes</strong>: Analytical cookies allow us to recognise, measure and track users of our site in anonymous form. The information collected includes the number of visitors to our site, where visitors have come to our site from and the pages that they have visited. This helps us to improve the way our site works, for example, by determining whether site visitors can find information easily, or by identifying the aspects of websites that are of the most interest to them.</li>
                        <li><strong>Usage preferences</strong>: Some of the cookies on our site are activated when visitors to our sites make a choice about their usage of our site. The site then ‘remembers’ the settings preferences of the user concerned. This allows us to tailor aspects of our site to the individual user.</li>
                        <li><strong>Disclaimer</strong>: We use cookies on our site to record when a site visitor has seen a policy, such as this one, or provided consent, such as consent to our Disclaimer. This helps to improve the user’s experience of our site – for example, it avoids a user from repeatedly being asked to consent to the same terms.</li>
                        <li><strong>Session management</strong>: The software that runs our websites uses cookies for technical purposes needed by the internal workings of our servers. For instance, we use cookies to distribute requests among multiple servers, authenticate users and determine what features of our site they can access, verify the origin of requests, keep track of information about a user’s session and determine which options or pages to display in order for our site to function.</li>
                        <li><strong>Functional purposes</strong>: Functional purpose cookies store information that is needed by our applications to process and operate. For example, where transactions or requests within an application involve multiple workflow stages, cookies are used to store the information from each stage temporarily, in order to facilitate completion of the overall transaction or request.</li>
                    </ul>
                    <p class="fivehundred">3. Your cookie preferences</p>
                    <p class="threehundred">To make full use of our site, your computer or mobile device will need to accept cookies, as our site will not function properly without them. In addition, cookies are required in order to provide you with personalized features on our site.</p>
                    <p class="fivehundred">4. Third party cookies</p>
                    <p class="threehundred">When you visit our website, you may receive cookies that are set by third parties. These may include cookies set by Google. These cookies are used for the purposes described in the “Why do we use cookies?” section of this policy. We do not control the setting of these third party cookies, so we suggest you might wish to check the third party websites for more information about their use of cookies and how to manage them.</p>
                    <p class="fivehundred">5. Amending cookie preferences</p>
                    <p class="threehundred">If you wish to remove cookies set by our site from your browser in the future, you may delete them. The instructions for removing cookies from your computer or mobile device depend on the operating system and web browser you use. Please note, however, that withdrawing your agreement to the use of cookies on our site will impair the functionality of our site.</p>
                    <p class="fivehundred">6. Information we may collect from you</p>
                    <p class="threehundred">We may collect and process the following data about you in accordance with this Cookies and Privacy Policy:</p>
                    <ol type="A">
                        <li>information that you provide to us via our site;</li>
                        <li>if you contact us, we may keep a record of that correspondence and may record telephone calls for regulatory compliance and record keeping purposes; and</li>
                        <li>details of your visits to our site and the materials and information that you access.</li>
                    </ol>
                    <h4>7. Use of your information</h4>
                    <ol type="A">
                        <li>We may use data held about you in the following ways:<br />
                            to support your interaction with us, including to ensure that the content of our site is presented in the most effective manner for you and your computer, to provide you with information or products you may have requested through our site, to notify you about changes to our site and to ensure the ongoing accuracy and relevance of such data;</li>
                        <li>to carry out any security or other verification activities to ensure the proper operation of our site or to ensure compliance with our regulatory obligations;</li>
                        <li>for general internal research purposes, including to identify and analyse the regularity of the use of our site and the nature of our site’s users; and</li>
                        <li>to design products and services for your use.</li>
                    </ol>
                    <p class="threehundred">We may disclose your personal data to our affiliates, agents and contractors (including to parties located outside the European Economic Area) for the purposes stated above. We may also disclose your personal data to third parties (including to parties located outside the European Economic Area):</p>
                    <ol type="A">
                        <li>in the event that we sell or buy, or one or more of our affiliates sells or buys, any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;</li>
                        <li>if we or our assets, or one or more of our affiliates or our affiliates assets’ are acquired by a third party, in which case personal data held by it about its clients may be one of the transferred assets;</li>
                        <li>if we, or one or more of our affiliates are under a duty to disclose or share your personal data in order to comply with any legal or regulatory obligation; or</li>
                        <li>in order to enforce or apply our Terms of Use and other agreements or to protect our rights, our customers or others.</li>
                    </ol>
                    <p class="threehundred">By using this site, you consent to processing of your personal data in accordance with our <a href="https://www.brevanhoward.com/disclaimer/">Terms of Use</a> and this Cookies and Privacy Policy and you warrant that all data provided by you is accurate.</p>
                </div>
            </div>

            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/vendor/foundation/js/vendor/jquery.js?ver=2.1.3"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/vendor/foundation/js/vendor/modernizr.js?ver=2.8.3"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/vendor/foundation/js/foundation.min.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/js.cookie.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/scripts.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-includes/js/wp-embed.min.js?ver=4.9.4"></script>
            <script type="text/javascript" src="https://portal.brevanhoward.com/wp-content/themes/xe7xclzx/assets/js/bhrequest.js?ver=4.9.4"></script>


        </body>

    );
};


const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ registration }, dispatch)
);

export default widthConnect(RegisterPage);
