import {

    ACTIVES_REQ_FAIL,
    ACTIVES_REQ_START,
    ACTIVES_REQ_SUCCESS,
    ACTIVES_CATEGORY_LIST_REQ_FAIL,
    ACTIVES_CATEGORY_LIST_REQ_START,
    ACTIVES_CATEGORY_LIST_REQ_SUCCESS,
    ACTIVES_OPEN_REQ_FAIL,
    ACTIVES_OPEN_REQ_START,
    ACTIVES_OPEN_REQ_SUCCESS

} from '../actions/actives';

const initialState = {
    data: null,
    loading: false,
    loadingSend: false,
    success: false,
    error: null,
};

const activesReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVES_CATEGORY_LIST_REQ_START:
        case ACTIVES_REQ_START:
            return {
                ...state,
                success: false,
                loading: true,
                error: null,
            };
        case ACTIVES_OPEN_REQ_START:
            return {
                ...state,
                loadingSend: true,
                error: null,
            };
        case ACTIVES_OPEN_REQ_SUCCESS:
            return {
                ...state,
                success: true,
                loadingSend: false,
                error: null,
            };
        case ACTIVES_REQ_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            }; 
        case ACTIVES_CATEGORY_LIST_REQ_SUCCESS:
            return {
                ...state,
                categoryList: action.payload,
                loading: false,
            };
        case ACTIVES_OPEN_REQ_FAIL:
        case ACTIVES_CATEGORY_LIST_REQ_FAIL:
        case ACTIVES_REQ_FAIL:
            return {
                ...state,
                loading: false,
                loadingSend: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default activesReducer;





