import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Radio, Button, Modal, Form, Input, Slider, InputNumber, Statistic, Select, Divider } from 'antd';
import CountUp from 'react-countup';
import { CaretRightOutlined } from '@ant-design/icons';
import { activesCategoryList } from '../../actions/actives';
import { AiFillCaretLeft } from "react-icons/ai";


const CreateProcessingActive = ({  }) => {
    const [inputValue, setInputValue] = useState(1);

    const onChange = (newValue) => {
        setInputValue(newValue);
    };

   

    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            {/* <Modal width={900} title="Класс активов" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}> */}
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}

                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    size="large"
                >
                    <Form.Item>
                        <Input style={{ borderRadius: 10 }} />
                    </Form.Item>

                    <Form.Item
                        label="Сумма вложений"
                        name="Slider"
                    >
                        <Row>
                            <Col span={20} style={{ display: 'flex', justifyContent: 'center' }}>
                                <AiFillCaretLeft />

                                <Slider
                                    min={500}
                                    max={999999}
                                    style={{ width: '90%' }}
                                    onChange={onChange}
                                    style={{ width: '90%', margin: '0', color: 'black' }}
                                    value={typeof inputValue === 'number' ? inputValue : 0}
                                    handleStyle={{ borderColor: 'black', backgroundColor: 'black' }}
                                    trackStyle={{ backgroundColor: 'black' }}
                                    railStyle={{ backgroundColor: 'black' }}
                                    trackStyle={{ backgroundColor: 'black' }}
                                >

                                </Slider>
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    min={500}
                                    max={999999}
                                    style={{
                                        margin: '0 16px',
                                    }}
                                    value={inputValue}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Отправить запрос
                        </Button>
                    </Form.Item>
                </Form>
            {/* </Modal> */}
        </>
    );
};

const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ }, dispatch)
);

export default widthConnect(CreateProcessingActive);
