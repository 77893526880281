import { React, useEffect, useState } from 'react';
import { Row, Col, Space, Button, Tag, Form, Input, Slider, InputNumber, Statistic, Table } from 'antd';
import CountUp from 'react-countup';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { historyList } from '../../actions/history';
import { Segmented, Typography, ConfigProvider } from 'antd';

const { Text } = Typography;



const formatter = (value) => <CountUp end={value} separator=" " decimals={2} decimal="," />;

function TablePage({ historyList, history, customer }) {
    const { Column, ColumnGroup } = Table;




    useEffect(() => {
        historyList()
    }, [])


    return (
        <section className='tablePage'>
            {/* <Row>
                <Col span={24} className='headerBalance'>
                    <span>
                        Ваш баланс:
                    </span>
                    <h2>
                        <Statistic value={112893} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                    </h2>
                </Col>
            </Row>
            <Row className='infographicBlock' gutter={16}>
                <Col span={8}>
                    <div className='infographicItem'>
                        <div className='infographicImg'>
                            <svg width="100" height="100" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M56.6778 55.853C52.7345 54.9836 51.4663 54.0846 51.4663 52.6846C51.4663 51.0783 53.2209 49.9583 56.1567 49.9583C59.2488 49.9583 60.3953 51.2109 60.4995 53.053H64.3386C64.217 50.5183 62.393 48.1899 58.7624 47.4383V44.2109H53.5509V47.3941C50.1808 48.013 47.4709 49.8699 47.4709 52.7141C47.4709 56.1183 50.7889 57.813 55.6355 58.8004C59.9784 59.6846 60.847 60.9815 60.847 62.352C60.847 63.3688 59.9958 64.9899 56.1567 64.9899C52.5781 64.9899 51.171 63.6341 50.9799 61.8951H47.1582C47.3667 65.1225 50.2156 66.9351 53.5509 67.5394V70.7373H58.7624V67.5688C62.1498 67.0236 64.8424 65.3583 64.8424 62.3373C64.8424 58.152 60.6211 56.7225 56.6778 55.853Z" fill="white" />
                                <path d="M56 106.105L64.5083 91.3685H47.4917L56 106.105ZM57.4737 92.8422V82.5264H54.5263V92.8422H57.4737Z" fill="white" />
                                <path d="M56 5.89469L64.5083 20.6315H47.4917L56 5.89469ZM57.4737 19.1578V29.4736H54.5263V19.1578H57.4737Z" fill="white" />
                                <path d="M106.105 56L91.3685 64.5083L91.3685 47.4917L106.105 56ZM92.8422 57.4737L82.5264 57.4737L82.5264 54.5263L92.8422 54.5263L92.8422 57.4737Z" fill="white" />
                                <path d="M5.89469 56L20.6315 64.5083L20.6315 47.4917L5.89469 56ZM19.1578 57.4737L29.4736 57.4737L29.4736 54.5263L19.1578 54.5263L19.1578 57.4737Z" fill="white" />
                                <circle cx="55.9997" cy="55.9997" r="19.1579" stroke="white" stroke-width="2.94737" />
                                <rect x="1.47368" y="1.47368" width="109.053" height="109.053" stroke="white" stroke-width="2.94737" stroke-dasharray="23.58 23.58" />
                            </svg>

                        </div>
                        <div className='infographicContent'>
                            <h5>
                                Вложеные средства
                            </h5>
                            <div className="price">
                                <Statistic value={112893} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                            </div>
                            <Button valueStyle={{ color: '#fff', }}>
                                Ивестировать
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='infographicItem'>
                        <div className='infographicImg'>
                            <svg width="100" height="100" viewBox="0 0 113 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M71.6803 74.6662L72.7363 73.6103L71.7202 72.5942L70.6658 73.5705L71.6803 74.6662ZM83.627 86.6129L82.5711 87.6688L83.5935 88.6913L84.6484 87.7023L83.627 86.6129ZM107.52 64.2129L90.7291 68.1365L102.523 80.7163L107.52 64.2129ZM32.3749 113.095L72.6949 75.762L70.6658 73.5705L30.3458 110.904L32.3749 113.095ZM70.6244 75.7222L82.5711 87.6688L84.683 85.5569L72.7363 73.6103L70.6244 75.7222ZM84.6484 87.7023L98.7367 74.4945L96.694 72.3156L82.6057 85.5235L84.6484 87.7023Z" fill="white" />
                                <path d="M106.027 4.48047L106.027 11.2005M106.027 17.9205V11.2005M106.027 11.2005H112.878H105.642M106.027 11.2005H105.642M99.168 11.2005H105.642" stroke="white" stroke-width="2.98667" />
                                <path d="M91.0967 29.8672L91.0965 37.3339M91.0965 44.8005V37.3339M91.0965 37.3339H98.5593H90.6778M91.0965 37.3339H90.6778M83.626 37.3339H90.6778" stroke="white" stroke-width="2.98667" />
                                <path d="M7.47073 80.6396L7.47048 88.1063M7.47048 95.573V88.1063M7.47048 88.1063H14.9333H7.05185M7.47048 88.1063H7.05185M0 88.1063H7.05185" stroke="white" stroke-width="2.98667" />
                                <circle cx="38.8267" cy="38.8267" r="37.3333" stroke="white" stroke-width="2.98667" />
                                <path d="M40.2003 36.4861C32.2084 34.4303 29.6384 32.3048 29.6384 28.9945C29.6384 25.1965 33.1942 22.5483 39.1441 22.5483C45.4108 22.5483 47.7344 25.5101 47.9457 29.8657H55.7262C55.4798 23.8724 51.7831 18.367 44.425 16.5899V8.95898H33.8631V16.4854C27.0331 17.9489 21.5409 22.3393 21.5409 29.0642C21.5409 37.1133 28.2653 41.1204 38.0879 43.455C46.8895 45.5457 48.6498 48.612 48.6498 51.8525C48.6498 54.2568 46.9247 58.0897 39.1441 58.0897C31.8916 58.0897 29.0399 54.884 28.6526 50.7723H20.9072C21.3297 58.4033 27.1035 62.6891 33.8631 64.1177V71.679H44.425V64.1874C51.2903 62.8982 56.7472 58.9608 56.7472 51.8177C56.7472 41.9218 48.1921 38.5419 40.2003 36.4861Z" fill="white" />
                            </svg>

                        </div>
                        <div className='infographicContent'>
                            <h5>
                                Прибль
                            </h5>
                            <div className="price">
                                <Statistic value={112893} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='infographicItem'>
                        <div className='infographicImg'>
                            <svg width="100" height="100" viewBox="0 0 117 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M44.7368 89.8944L52.2608 95.2687C53.9784 96.4955 56.2334 96.6725 58.1213 95.7285V95.7285C61.1869 94.1957 62.2154 90.3228 60.3142 87.471L55.7066 80.5596C55.2749 79.912 54.7192 79.3564 54.0717 78.9247L41.7895 70.7365M41.7895 70.7365H19.6842M41.7895 70.7365L55.8322 73.5451C56.2933 73.6373 56.7638 73.674 57.2336 73.6544L91.8947 72.2102L104.697 71.1434C107.325 70.9244 109.579 72.9984 109.579 75.6357V75.6357C109.579 77.174 108.794 78.6062 107.498 79.4344L63.7444 107.388C59.2819 110.239 53.6327 110.477 48.9466 108.01L29.8152 97.9412C28.9687 97.4957 28.0264 97.2628 27.0698 97.2628H19.6842M19.6842 70.7365V70.7365C19.6842 67.481 17.045 64.8418 13.7895 64.8418H7.89474C4.63917 64.8418 2 67.481 2 70.7365V98.4254C2 99.5892 2.34448 100.727 2.99002 101.695L3.19742 102.006C4.29069 103.646 6.13121 104.631 8.10214 104.631H14.4067C16.6783 104.631 18.6951 103.178 19.4134 101.023V101.023C19.5928 100.485 19.6842 99.921 19.6842 99.3538V97.2628M19.6842 70.7365V97.2628" stroke="white" stroke-width="2.94737" />
                                <path d="M30 0V11.7895" stroke="white" stroke-width="2.94737" />
                                <path d="M63.8945 11.7891L63.8945 29.4733" stroke="white" stroke-width="2.94737" />
                                <path d="M99.2637 8.8418L99.2637 20.6313" stroke="white" stroke-width="2.94737" />
                                <path d="M99.2637 0L99.2637 5.89474" stroke="white" stroke-width="2.94737" />
                                <path d="M63.8945 0L63.8945 5.89474" stroke="white" stroke-width="2.94737" />
                                <circle cx="28.526" cy="35.3688" r="16.2105" stroke="white" stroke-width="2.94737" />
                                <circle cx="99.2633" cy="41.2623" r="16.2105" stroke="white" stroke-width="2.94737" />
                                <circle cx="63.8951" cy="50.1051" r="16.2105" stroke="white" stroke-width="2.94737" />
                                <path d="M28.3292 34.2868C26.099 33.7071 25.3818 33.1078 25.3818 32.1745C25.3818 31.1036 26.3741 30.357 28.0344 30.357C29.7832 30.357 30.4316 31.1921 30.4906 32.4201H32.6618C32.593 30.7303 31.5615 29.178 29.5081 28.677V26.5254H26.5608V28.6475C24.6548 29.0601 23.1222 30.298 23.1222 32.1942C23.1222 34.4636 24.9986 35.5935 27.7397 36.2517C30.1958 36.8412 30.6871 37.7057 30.6871 38.6194C30.6871 39.2973 30.2057 40.378 28.0344 40.378C26.0106 40.378 25.2148 39.4742 25.1067 38.3149H22.9453C23.0632 40.4664 24.6744 41.6749 26.5608 42.0777V44.2096H29.5081V42.0973C31.4239 41.7338 32.9467 40.6236 32.9467 38.6096C32.9467 35.8194 30.5593 34.8664 28.3292 34.2868Z" fill="white" />
                                <path d="M63.6973 49.0241C61.4672 48.4444 60.75 47.8452 60.75 46.9118C60.75 45.8409 61.7422 45.0943 63.4026 45.0943C65.1514 45.0943 65.7998 45.9294 65.8587 47.1574H68.03C67.9612 45.4676 66.9296 43.9153 64.8763 43.4143V41.2627H61.9289V43.3848C60.0229 43.7974 58.4903 45.0353 58.4903 46.9315C58.4903 49.2009 60.3668 50.3308 63.1079 50.989C65.564 51.5785 66.0552 52.443 66.0552 53.3567C66.0552 54.0346 65.5738 55.1153 63.4026 55.1153C61.3787 55.1153 60.583 54.2115 60.4749 53.0522H58.3135C58.4314 55.2037 60.0426 56.4122 61.9289 56.815V58.9469H64.8763V56.8346C66.7921 56.4711 68.3149 55.3609 68.3149 53.3469C68.3149 50.5567 65.9275 49.6037 63.6973 49.0241Z" fill="white" />
                                <path d="M99.0665 40.1823C96.8363 39.6027 96.1191 39.0034 96.1191 38.07C96.1191 36.9991 97.1114 36.2525 98.7717 36.2525C100.521 36.2525 101.169 37.0876 101.228 38.3156H103.399C103.33 36.6258 102.299 35.0735 100.245 34.5725V32.4209H97.2981V34.543C95.3921 34.9556 93.8595 36.1935 93.8595 38.0897C93.8595 40.3591 95.7359 41.489 98.477 42.1472C100.933 42.7367 101.424 43.6012 101.424 44.5149C101.424 45.1928 100.943 46.2735 98.7717 46.2735C96.7479 46.2735 95.9521 45.3697 95.844 44.2104H93.6826C93.8005 46.3619 95.4117 47.5704 97.2981 47.9732V50.1051H100.245V47.9928C102.161 47.6293 103.684 46.5191 103.684 44.5051C103.684 41.7149 101.297 40.762 99.0665 40.1823Z" fill="white" />
                            </svg>

                        </div>
                        <div className='infographicContent'>
                            <h5>
                                Выведенные средства
                            </h5>
                            <div className="price">
                                <Statistic value={112893} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}



            <Row>
                <Col span={24} className='headerBalance'>
                    <span>
                        Ваш баланс:

                    </span>
                    <Statistic value={customer.data.balance} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                </Col>
            </Row>
            <Row className='infographicBlock' gutter={16}>
                <Col span={8}>
                    <div className='infographicItem'>
                        <div className='infographicImg'>
                            <svg width="100" height="100" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M56.6778 55.853C52.7345 54.9836 51.4663 54.0846 51.4663 52.6846C51.4663 51.0783 53.2209 49.9583 56.1567 49.9583C59.2488 49.9583 60.3953 51.2109 60.4995 53.053H64.3386C64.217 50.5183 62.393 48.1899 58.7624 47.4383V44.2109H53.5509V47.3941C50.1808 48.013 47.4709 49.8699 47.4709 52.7141C47.4709 56.1183 50.7889 57.813 55.6355 58.8004C59.9784 59.6846 60.847 60.9815 60.847 62.352C60.847 63.3688 59.9958 64.9899 56.1567 64.9899C52.5781 64.9899 51.171 63.6341 50.9799 61.8951H47.1582C47.3667 65.1225 50.2156 66.9351 53.5509 67.5394V70.7373H58.7624V67.5688C62.1498 67.0236 64.8424 65.3583 64.8424 62.3373C64.8424 58.152 60.6211 56.7225 56.6778 55.853Z" fill="white" />
                                <path d="M56 106.105L64.5083 91.3685H47.4917L56 106.105ZM57.4737 92.8422V82.5264H54.5263V92.8422H57.4737Z" fill="white" />
                                <path d="M56 5.89469L64.5083 20.6315H47.4917L56 5.89469ZM57.4737 19.1578V29.4736H54.5263V19.1578H57.4737Z" fill="white" />
                                <path d="M106.105 56L91.3685 64.5083L91.3685 47.4917L106.105 56ZM92.8422 57.4737L82.5264 57.4737L82.5264 54.5263L92.8422 54.5263L92.8422 57.4737Z" fill="white" />
                                <path d="M5.89469 56L20.6315 64.5083L20.6315 47.4917L5.89469 56ZM19.1578 57.4737L29.4736 57.4737L29.4736 54.5263L19.1578 54.5263L19.1578 57.4737Z" fill="white" />
                                <circle cx="55.9997" cy="55.9997" r="19.1579" stroke="white" stroke-width="2.94737" />
                                <rect x="1.47368" y="1.47368" width="109.053" height="109.053" stroke="white" stroke-width="2.94737" stroke-dasharray="23.58 23.58" />
                            </svg>

                        </div>
                        <div className='infographicContent'>
                            <h5>
                                Вложеные средства
                            </h5>
                            <div className="price">
                                <Statistic value={customer.data.income} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                            </div>
                            {/* <Button valueStyle={{ color: '#fff', }}>
                                Ивестировать
                            </Button> */}
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='infographicItem'>
                        <div className='infographicImg'>
                            <svg width="100" height="100" viewBox="0 0 113 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M71.6803 74.6662L72.7363 73.6103L71.7202 72.5942L70.6658 73.5705L71.6803 74.6662ZM83.627 86.6129L82.5711 87.6688L83.5935 88.6913L84.6484 87.7023L83.627 86.6129ZM107.52 64.2129L90.7291 68.1365L102.523 80.7163L107.52 64.2129ZM32.3749 113.095L72.6949 75.762L70.6658 73.5705L30.3458 110.904L32.3749 113.095ZM70.6244 75.7222L82.5711 87.6688L84.683 85.5569L72.7363 73.6103L70.6244 75.7222ZM84.6484 87.7023L98.7367 74.4945L96.694 72.3156L82.6057 85.5235L84.6484 87.7023Z" fill="white" />
                                <path d="M106.027 4.48047L106.027 11.2005M106.027 17.9205V11.2005M106.027 11.2005H112.878H105.642M106.027 11.2005H105.642M99.168 11.2005H105.642" stroke="white" stroke-width="2.98667" />
                                <path d="M91.0967 29.8672L91.0965 37.3339M91.0965 44.8005V37.3339M91.0965 37.3339H98.5593H90.6778M91.0965 37.3339H90.6778M83.626 37.3339H90.6778" stroke="white" stroke-width="2.98667" />
                                <path d="M7.47073 80.6396L7.47048 88.1063M7.47048 95.573V88.1063M7.47048 88.1063H14.9333H7.05185M7.47048 88.1063H7.05185M0 88.1063H7.05185" stroke="white" stroke-width="2.98667" />
                                <circle cx="38.8267" cy="38.8267" r="37.3333" stroke="white" stroke-width="2.98667" />
                                <path d="M40.2003 36.4861C32.2084 34.4303 29.6384 32.3048 29.6384 28.9945C29.6384 25.1965 33.1942 22.5483 39.1441 22.5483C45.4108 22.5483 47.7344 25.5101 47.9457 29.8657H55.7262C55.4798 23.8724 51.7831 18.367 44.425 16.5899V8.95898H33.8631V16.4854C27.0331 17.9489 21.5409 22.3393 21.5409 29.0642C21.5409 37.1133 28.2653 41.1204 38.0879 43.455C46.8895 45.5457 48.6498 48.612 48.6498 51.8525C48.6498 54.2568 46.9247 58.0897 39.1441 58.0897C31.8916 58.0897 29.0399 54.884 28.6526 50.7723H20.9072C21.3297 58.4033 27.1035 62.6891 33.8631 64.1177V71.679H44.425V64.1874C51.2903 62.8982 56.7472 58.9608 56.7472 51.8177C56.7472 41.9218 48.1921 38.5419 40.2003 36.4861Z" fill="white" />
                            </svg>

                        </div>
                        <div className='infographicContent'>
                            <h5>
                                Прибыль
                            </h5>
                            <div className="price">
                                <Statistic value={customer.data.profit} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className='infographicItem'>
                        <div className='infographicImg'>
                            <svg width="100" height="100" viewBox="0 0 117 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M44.7368 89.8944L52.2608 95.2687C53.9784 96.4955 56.2334 96.6725 58.1213 95.7285V95.7285C61.1869 94.1957 62.2154 90.3228 60.3142 87.471L55.7066 80.5596C55.2749 79.912 54.7192 79.3564 54.0717 78.9247L41.7895 70.7365M41.7895 70.7365H19.6842M41.7895 70.7365L55.8322 73.5451C56.2933 73.6373 56.7638 73.674 57.2336 73.6544L91.8947 72.2102L104.697 71.1434C107.325 70.9244 109.579 72.9984 109.579 75.6357V75.6357C109.579 77.174 108.794 78.6062 107.498 79.4344L63.7444 107.388C59.2819 110.239 53.6327 110.477 48.9466 108.01L29.8152 97.9412C28.9687 97.4957 28.0264 97.2628 27.0698 97.2628H19.6842M19.6842 70.7365V70.7365C19.6842 67.481 17.045 64.8418 13.7895 64.8418H7.89474C4.63917 64.8418 2 67.481 2 70.7365V98.4254C2 99.5892 2.34448 100.727 2.99002 101.695L3.19742 102.006C4.29069 103.646 6.13121 104.631 8.10214 104.631H14.4067C16.6783 104.631 18.6951 103.178 19.4134 101.023V101.023C19.5928 100.485 19.6842 99.921 19.6842 99.3538V97.2628M19.6842 70.7365V97.2628" stroke="white" stroke-width="2.94737" />
                                <path d="M30 0V11.7895" stroke="white" stroke-width="2.94737" />
                                <path d="M63.8945 11.7891L63.8945 29.4733" stroke="white" stroke-width="2.94737" />
                                <path d="M99.2637 8.8418L99.2637 20.6313" stroke="white" stroke-width="2.94737" />
                                <path d="M99.2637 0L99.2637 5.89474" stroke="white" stroke-width="2.94737" />
                                <path d="M63.8945 0L63.8945 5.89474" stroke="white" stroke-width="2.94737" />
                                <circle cx="28.526" cy="35.3688" r="16.2105" stroke="white" stroke-width="2.94737" />
                                <circle cx="99.2633" cy="41.2623" r="16.2105" stroke="white" stroke-width="2.94737" />
                                <circle cx="63.8951" cy="50.1051" r="16.2105" stroke="white" stroke-width="2.94737" />
                                <path d="M28.3292 34.2868C26.099 33.7071 25.3818 33.1078 25.3818 32.1745C25.3818 31.1036 26.3741 30.357 28.0344 30.357C29.7832 30.357 30.4316 31.1921 30.4906 32.4201H32.6618C32.593 30.7303 31.5615 29.178 29.5081 28.677V26.5254H26.5608V28.6475C24.6548 29.0601 23.1222 30.298 23.1222 32.1942C23.1222 34.4636 24.9986 35.5935 27.7397 36.2517C30.1958 36.8412 30.6871 37.7057 30.6871 38.6194C30.6871 39.2973 30.2057 40.378 28.0344 40.378C26.0106 40.378 25.2148 39.4742 25.1067 38.3149H22.9453C23.0632 40.4664 24.6744 41.6749 26.5608 42.0777V44.2096H29.5081V42.0973C31.4239 41.7338 32.9467 40.6236 32.9467 38.6096C32.9467 35.8194 30.5593 34.8664 28.3292 34.2868Z" fill="white" />
                                <path d="M63.6973 49.0241C61.4672 48.4444 60.75 47.8452 60.75 46.9118C60.75 45.8409 61.7422 45.0943 63.4026 45.0943C65.1514 45.0943 65.7998 45.9294 65.8587 47.1574H68.03C67.9612 45.4676 66.9296 43.9153 64.8763 43.4143V41.2627H61.9289V43.3848C60.0229 43.7974 58.4903 45.0353 58.4903 46.9315C58.4903 49.2009 60.3668 50.3308 63.1079 50.989C65.564 51.5785 66.0552 52.443 66.0552 53.3567C66.0552 54.0346 65.5738 55.1153 63.4026 55.1153C61.3787 55.1153 60.583 54.2115 60.4749 53.0522H58.3135C58.4314 55.2037 60.0426 56.4122 61.9289 56.815V58.9469H64.8763V56.8346C66.7921 56.4711 68.3149 55.3609 68.3149 53.3469C68.3149 50.5567 65.9275 49.6037 63.6973 49.0241Z" fill="white" />
                                <path d="M99.0665 40.1823C96.8363 39.6027 96.1191 39.0034 96.1191 38.07C96.1191 36.9991 97.1114 36.2525 98.7717 36.2525C100.521 36.2525 101.169 37.0876 101.228 38.3156H103.399C103.33 36.6258 102.299 35.0735 100.245 34.5725V32.4209H97.2981V34.543C95.3921 34.9556 93.8595 36.1935 93.8595 38.0897C93.8595 40.3591 95.7359 41.489 98.477 42.1472C100.933 42.7367 101.424 43.6012 101.424 44.5149C101.424 45.1928 100.943 46.2735 98.7717 46.2735C96.7479 46.2735 95.9521 45.3697 95.844 44.2104H93.6826C93.8005 46.3619 95.4117 47.5704 97.2981 47.9732V50.1051H100.245V47.9928C102.161 47.6293 103.684 46.5191 103.684 44.5051C103.684 41.7149 101.297 40.762 99.0665 40.1823Z" fill="white" />
                            </svg>

                        </div>
                        <div className='infographicContent'>
                            <h5>
                                Выведенные средства
                            </h5>
                            <div className="price">
                                <Statistic value={customer.data.outcome} valueStyle={{ color: '#fff', }} prefix="$" formatter={formatter} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>

                    <Table
                        dataSource={history?.data}
                        title={() => 'Начисления'}
                    >
                        <Column title="#" dataIndex="id" key="id" />
                        <Column title="Дата" dataIndex="date" key="date" />
                        <Column title="Описание" dataIndex="action" key="action" />
                        {/* <Column
                            title="Tags"
                            dataIndex="tags"
                            key="tags"
                            render={(tags) => (
                                <>
                                    {tags.map((tag) => {
                                        let color = tag.length > 5 ? 'geekblue' : 'green';
                                        if (tag === 'loser') {
                                            color = 'volcano';
                                        }
                                        return (
                                            <Tag color={color} key={tag}>
                                                {tag.toUpperCase()}
                                            </Tag>
                                        );
                                    })}
                                </>
                            )}
                        /> */}
                    </Table>
                </Col>

            </Row>
        </section>

    )

};
const widthConnect = connect(
    (state) => ({ ...state }),
    (dispatch) => bindActionCreators({ historyList }, dispatch)
);
export default widthConnect(TablePage);
