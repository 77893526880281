import { Navigate } from 'react-router-dom';
import ThemeConfig from '../theme/ThemeConfig';

export default function ProtectedLoginRoute({ element, isAuthenticated, isWish }) {
  return (isAuthenticated) ? (
    <>
      <Navigate to="/home" replace/>
    </>
  ) : (
    <>
      <ThemeConfig>
        {element}
      </ThemeConfig>
    </>
  )
}