import { sendRequest } from './request';



export const auth = (data) => {
    return sendRequest('/customers/login', 'POST', { ...data });
};

export const regRequest = (data) => {
    return sendRequest('/customers/registration', 'POST', data );
};

export const customersInfoRequest = () => {
    return sendRequest('/customers', 'GET' );
};