import { Navigate } from 'react-router-dom';
import LayoutCustom from '../components/Layout';
import ThemeConfig from '../theme/ThemeConfig';

export default function ProtectedRoute({ element, isAuthenticated }) {
  return (isAuthenticated) ? (
    <ThemeConfig>
      <LayoutCustom>
        {element}
      </LayoutCustom>
    </ThemeConfig>
  ) : (
    <>
      <Navigate to="/login" />
    </>
  )

}